// import { LoadingButton } from "@mui/lab";
// import {
//   Alert,
//   Box,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   Radio,
//   RadioGroup,
//   Skeleton,
//   Typography,
// } from "@mui/material";
// import { AxiosError } from "axios";
// import React, { useEffect, useMemo, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getVirtualCard } from "../api/paymentMethodsService";
// import { usePageTitle } from "../common/TitleContext";
// import { WhiteSecurityIcon } from "../common/constants";
// import useDecrypt from "../common/useDecrypt";
// import CreateVccCard from "../components/VccCard/CreateVccCard";
// import {
//   paymentMethods,
//   setLatestPaymentMethod,
// } from "../redux/slices/paymentMethodsSlice";
// import { setVccData } from "../redux/slices/vccSlice";
// import { RootState } from "../redux/store";
// import StorageManager from "../services/storage";
// import {
//   PaymentAccountDetails,
//   PaymentType,
//   VirtualCardResponse,
// } from "../types/paymentTypes";
// import { useErrorHandler } from "../common/hooks";

const PaymentMethods: React.FC = () => {
  return null;
  //   const { t } = useTranslation();
  //   const { handleServerError } = useErrorHandler();

  //   usePageTitle(t("mileageReview_PaymentMethod"));

  //   const navigate = useNavigate();
  //   const dispatch = useDispatch();

  //   let { id } = useParams<{ id: string }>();

  //   const location = useLocation();
  //   const searchParams = new URLSearchParams(location.search);
  //   const failedPayment = searchParams.get("failedPayment") === "true";

  //   const paymentMethodsData = useSelector(paymentMethods);
  //   const stipends = useSelector(
  //     (state: RootState) => state.claims.waitingStipends
  //   );
  //   const latestPaymentMethod = useSelector(
  //     (state: RootState) => state.paymentMethods.latestPaymentAccount
  //   ) as any;
  //   const vcc = useSelector<RootState, any | null>((state) => state.vcc.vccData);

  //   const isVccMethodEnabled = useMemo(() => {
  //     return paymentMethodsData.some(
  //       (x) => x.payment_type == PaymentType.VirtualCard
  //     );
  //   }, [paymentMethodsData]);

  //   const hasVcc = vcc !== null;

  //   const { decryptedId: decryptedVccValue } = useDecrypt(
  //     vcc?.target_account_identifier,
  //     [vcc]
  //   );

  //   const sumAmountFromStipends = useMemo(() => {
  //     return stipends.reduce((accumulator, currentValue) => {
  //       return accumulator + (currentValue.amount ?? 0);
  //     }, 0);
  //   }, [stipends?.length]);

  //   const [isLoading, setLoading] = useState(false);
  //   const [selectedPaymentType, setSelectedPaymentType] = useState<number>(-1);
  //   const [selectedMethod, setSelectedMethod] = useState<any>(null);
  //   const [isVCCLoading, setIsVCCLoading] = useState<boolean>(false);
  //   // const [vccError, setVccError] = useState<any>(null);

  //   useEffect(() => {
  //     if (selectedPaymentType !== -1) {
  //       const method = paymentMethodsData[selectedPaymentType];
  //       setSelectedMethod(method);
  //     }
  //   }, [selectedPaymentType, paymentMethodsData]);

  //   // useEffect(() => {
  //   //   if (!vccError) {
  //   //     return;
  //   //   }

  //   //   console.log("vccError.response?.status", vccError.response?.status);
  //   //   // if 404, the user doesn't have a vcc
  //   //   if (vccError.response?.status == 404 || vccError.response?.status == 500) {
  //   //     return;
  //   //   }
  //   //   showBoundary(vccError);
  //   // }, [vccError]);

  //   useEffect(() => {
  //     if (isVccMethodEnabled) {
  //       setIsVCCLoading(true);
  //       let vccData = null;

  //       api<VirtualCardResponse>(getVirtualCard())
  //         .then((response) => {
  //           vccData = response.data?.vccs[0];
  //         })
  //         .catch((error) => {
  //           // unify vcc loading business logic in backend and then handle vcc loading errors properly
  //           // setVccError(error);
  //         })
  //         .finally(() => {
  //           setIsVCCLoading(false);
  //         });

  //       if (vccData) {
  //         dispatch(setVccData(vccData));
  //       }
  //     }
  //   }, [isVccMethodEnabled]);

  //   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setSelectedPaymentType(parseInt(event.target.value, 10));
  //   };

  //   const handleContinueClick = async () => {
  //     if (selectedMethod.payment_type === PaymentType.VirtualCard && hasVcc) {
  //       if (latestPaymentMethod.target_account_type !== PaymentType.VirtualCard) {
  //         const { balance, ...vccReqWithoutBalance } = vcc;

  //         dispatch(setLatestPaymentMethod(vccReqWithoutBalance));
  //       }

  //       if (hasVcc && vcc?.target_account_id) {
  //         setLoading(true);

  //         try {
  //           const vccPaymentCreationData: PaymentAccountDetails = {
  //             target_account_id: vcc.target_account_id,
  //             target_account_type: "Virtual credit card",
  //             target_account_title: vcc.target_account_title,
  //             payment_currency: vcc.currency,
  //             target_account_identifier: vcc.target_account_identifier,
  //             payment_country_iso: vcc.country_iso,
  //           };

  //           dispatch(setLatestPaymentMethod(vccPaymentCreationData));

  //           navigate(`/payment/submit/${id}/summary`);
  //         } catch (err) {
  //           handleServerError(err as AxiosError);
  //         } finally {
  //           setLoading(false);
  //         }

  //         return;
  //       }
  //     }

  //     if (selectedMethod) {
  //       StorageManager.removeFormData();

  //       navigate(`/payment/submit/${id}/payment-methods/${selectedPaymentType}`);
  //     }
  //   };

  //   return (
  //     <>
  //       {!latestPaymentMethod && (
  //         <Alert
  //           data-test-id="amount-alert"
  //           severity="info"
  //           sx={{
  //             backgroundColor: "#0588D1",
  //             marginBottom: "10px",
  //             fontWeight: "bold",
  //             color: "white",
  //           }}
  //         >
  //           {sumAmountFromStipends === 0
  //             ? t("payment_description")
  //             : t("paymentMethod_amountWaiting", {
  //                 "0": sumAmountFromStipends?.toFixed(2),
  //               })}
  //         </Alert>
  //       )}

  //       {failedPayment && (
  //         <Alert
  //           data-test-id="retry-alert"
  //           severity="info"
  //           sx={{
  //             backgroundColor: "#0588D1",
  //             marginBottom: "10px",
  //             fontWeight: "bold",
  //             color: "white",
  //           }}
  //         >
  //           {t("paymentMethod_retry")}
  //         </Alert>
  //       )}

  //       {isVCCLoading && (
  //         <Skeleton
  //           data-test-id="vcc-skeleton"
  //           variant="rounded"
  //           height={142}
  //           style={{
  //             marginBottom: "16px",
  //           }}
  //         />
  //       )}

  //       {!isVCCLoading && (
  //         <Grid
  //           container
  //           direction="column"
  //           borderRadius="4px"
  //           border="1px solid grey"
  //           sx={{ backgroundColor: "white" }}
  //           p={2}
  //           mb={2}
  //         >
  //           <form>
  //             <Grid textAlign="left" item>
  //               <FormControl component="fieldset">
  //                 <RadioGroup
  //                   data-test-id="payment-type"
  //                   name="payment-type"
  //                   value={selectedPaymentType.toString()}
  //                   onChange={handleChange}
  //                 >
  //                   {paymentMethodsData
  //                     .filter((method: any) => {
  //                       if (hasVcc) {
  //                         return method.payment_type !== PaymentType.Cash;
  //                       } else {
  //                         return (
  //                           method.payment_type !== PaymentType.Cash &&
  //                           method.payment_type !== PaymentType.VirtualCard
  //                         );
  //                       }
  //                     })
  //                     .map((method: any) => (
  //                       <FormControlLabel
  //                         sx={{ fontSize: "0.875rem" }}
  //                         key={method.id}
  //                         value={method.id.toString()}
  //                         control={<Radio />}
  //                         label={
  //                           <Typography
  //                             data-test-id="payment-method-title"
  //                             fontSize="0.875rem"
  //                             style={{ display: "flex" }}
  //                           >
  //                             {method.payment_type === PaymentType.VirtualCard &&
  //                               hasVcc && (
  //                                 <>
  //                                   {t("claimDetail_vccDescription", {
  //                                     "0": decryptedVccValue ?? "",
  //                                   })}

  //                                   {decryptedVccValue === null &&
  //                                   vcc?.target_account_id ? ( // Check for loading here
  //                                     <span>
  //                                       <Skeleton
  //                                         variant="rounded"
  //                                         height={20}
  //                                         width={45}
  //                                         style={{ marginLeft: "10px" }}
  //                                       />
  //                                     </span>
  //                                   ) : null}
  //                                 </>
  //                               )}

  //                             {method.payment_type !== PaymentType.VirtualCard &&
  //                               method.title}
  //                           </Typography>
  //                         }
  //                       />
  //                     ))}
  //                 </RadioGroup>
  //               </FormControl>
  //             </Grid>

  //             <Grid item width="100%">
  //               <Box mt={3}>
  //                 <LoadingButton
  //                   data-test-id="payment-methods-button"
  //                   fullWidth
  //                   size="large"
  //                   variant="contained"
  //                   disabled={!selectedMethod}
  //                   onClick={handleContinueClick}
  //                   loading={isLoading}
  //                   loadingPosition="start"
  //                   type="submit"
  //                   startIcon={<WhiteSecurityIcon />}
  //                 >
  //                   <span> {t("continue_text")}</span>
  //                 </LoadingButton>
  //               </Box>
  //             </Grid>
  //           </form>
  //         </Grid>
  //       )}

  //       {!hasVcc && isVccMethodEnabled && <CreateVccCard />}
  //     </>
  //   );
};

export default PaymentMethods;
