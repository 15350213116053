import Error from "./Error";
import { getError } from "../utils/utils";
import { Navigate } from "react-router-dom";
import { NotAuthonticatedError } from "../types/NotAuthonticatedError";

interface FallbackProps {
  error: any;
  resetErrorBoundary: () => void;
}

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const parsedError = getError(error);

  if (parsedError instanceof NotAuthonticatedError) {
    return <Navigate to="." replace />;
  }

  const buttonAction = (
    error: Error,
    resetErrorBoundary: () => void
  ): (() => void) => {
    //TOOD: customize it based on error types
    return () => {
      resetErrorBoundary();
    };
  };

  return (
    <Error {...error} buttonAction={buttonAction(error, resetErrorBoundary)} />
  );
}
