import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { setupInterceptors } from "./Interceptors";

export const nmibleAPI = Axios.create({
  baseURL: import.meta.env.VITE_NMIBLE_SERVER,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
});

export const evervaultAPI = Axios.create({
  baseURL: import.meta.env.VITE_EVERVAULT_SERVER,
  headers: {
    Accept: "application/vnd.api+json",

    "Content-Type": "application/vnd.api+json",
    version: "v0.1",
  },
});

export const loqateAPI = Axios.create({
  baseURL: import.meta.env.VITE_LOQATE_SERVER,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
});

setupInterceptors(nmibleAPI);

// export const nmibleInstance = async <T>(
//   config: AxiosRequestConfig
// ): Promise<T> => {
//   const { data } = await nmibleAPI(config);
//   return data;
// };

export const nmibleInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = nmibleAPI({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;

export default nmibleInstance;
