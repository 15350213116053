import { Box, Divider, Skeleton, styled, Typography } from "@mui/material";
import React from "react";
import CardContainer from "../pages/CardContainer";

export interface SummaryPageSkeletonProps {
  showChangePaymentMethodButton: boolean;
  detailSectionsCount: number;
  actionsCount: number;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const SummaryPageSkeleton: React.FC<SummaryPageSkeletonProps> = ({
  showChangePaymentMethodButton,
  detailSectionsCount,
  actionsCount,
}) => {
  return (
    <CardContainer isLoading={true}>
      <CardContainer.Header>
        <CardContainer.Icon>
          <Box />
        </CardContainer.Icon>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            <Typography />
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
        <CardContainer.TopRightContent>
          <Box />
        </CardContainer.TopRightContent>
      </CardContainer.Header>

      <CardContainer.Content>
        <Skeleton width="20%" height={24} />
        <Skeleton width="30%" height={20} />

        <StyledDivider />

        {Array.from({ length: detailSectionsCount }).map((_, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton width="20%" height={20} />

            <Skeleton width="20%" height={24} />
          </Box>
        ))}

        <Divider style={{ margin: "20px 0", borderBottomWidth: "thick" }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton width="20%" height={24} />
          <Skeleton width="10%" height={24} />
        </Box>

        {showChangePaymentMethodButton && (
          <Box mt={2}>
            <Skeleton width="30%" height={36} />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mt: 2,
            justifyContent: "end",
          }}
        >
          {Array.from({ length: actionsCount }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height={36}
              sx={{ mb: 2 }}
            />
          ))}
        </Box>
      </CardContainer.Content>
    </CardContainer>
  );
};

export default SummaryPageSkeleton;
