import { LoginData, Token } from "../types/common";
import Cookies from "js-cookie";

enum Keys {
  FIRST_TIME_USER = "first_time_user",
  FORM_DATA = "form_data",
  displayedGlobalMessage = "Displayed_Global_Message",
  hideProtectYourIdentityMessage = "hide_protect_your_identity",
  SERVER_URL = "server_url",
  LOGIN_INFORMATION = "login_information",
  DEVICE_ID = "device_id",
  TOKEN_DATA = "token_data",
  DISMISS_PWA_INSTALL_DATE = "dismiss_pwa_install_date",
  PWA_INSTALL_INSTRUCTIONS_SHOWN = "pwa_install_instructions_shown",
}

// TODO: we need to consider implementing encryption for some data stored in local storage
export default class StorageManager {
  static clear() {
    StorageManager.removeFormData();
    StorageManager.removeLoginInformation();
    StorageManager.removeToken();
  }

  static setProtectYourIdentityMessage(value: boolean) {
    localStorage.setItem(
      Keys.hideProtectYourIdentityMessage,
      value ? "true" : "false"
    );
  }

  static hideProtectYourIdentityMessage(): boolean {
    return localStorage.getItem(Keys.hideProtectYourIdentityMessage) === "true";
  }

  static setDisplayedGlobalMessage(): void {
    sessionStorage.setItem(Keys.displayedGlobalMessage, "true");
  }

  static didDisplayGlobalMessage(): boolean {
    return sessionStorage.getItem(Keys.displayedGlobalMessage) === "true";
  }

  // Methods for form data
  static setFormData(formData: Record<string, string>): void {
    sessionStorage.setItem(Keys.FORM_DATA, JSON.stringify(formData));
  }

  static getFormData(): Record<string, string> | null {
    const data = sessionStorage.getItem(Keys.FORM_DATA);
    return data ? JSON.parse(data) : null;
  }

  static removeFormData() {
    sessionStorage.removeItem(Keys.FORM_DATA);
  }

  // Methods for login information - Use session so it's wiped on tab close
  static setLoginInformation(loginData: LoginData): void {
    sessionStorage.setItem(Keys.LOGIN_INFORMATION, JSON.stringify(loginData));
  }

  static getLoginInformation(): LoginData | null {
    const data = sessionStorage.getItem(Keys.LOGIN_INFORMATION);

    return data ? JSON.parse(data) : null;
  }

  static removeLoginInformation() {
    sessionStorage.removeItem(Keys.LOGIN_INFORMATION);
  }

  static getDeviceId(): string {
    return localStorage.getItem(Keys.DEVICE_ID) || "";
  }

  static setDeviceId(deviceId: string): void {
    return localStorage.setItem(Keys.DEVICE_ID, deviceId);
  }

  // token storage
  static setTokenData(tokenData: Token): void {
    const jsonStr = JSON.stringify(tokenData);
    Cookies.set(Keys.TOKEN_DATA, jsonStr, {
      expires: 1 / 24, // 1 hour
      secure: true,
      sameSite: "strict",
      path: "/",
    });
  }

  static getTokenData(): Token | null {
    const cookieVal = Cookies.get(Keys.TOKEN_DATA);
    if (!cookieVal) {
      return null;
    }
    try {
      return JSON.parse(cookieVal) as Token;
    } catch (e) {
      console.error("Failed to parse token data from cookie", e);
      return null;
    }
  }

  static updateRequiresPinChange(value: boolean): void {
    const tokenData = this.getTokenData();
    if (tokenData) {
      tokenData.requiresPinChange = value;
      this.setTokenData(tokenData);
    }
  }

  static removeToken() {
    Cookies.remove(Keys.TOKEN_DATA, { path: "/" });
  }

  // PWA Install Prompt Dismiss Date
  static setDismissPWAInstallDate(): void {
    localStorage.setItem(
      Keys.DISMISS_PWA_INSTALL_DATE,
      new Date().toISOString()
    );
  }

  static getDismissPWAInstallDate(): Date | null {
    const dateStr = localStorage.getItem(Keys.DISMISS_PWA_INSTALL_DATE);
    return dateStr ? new Date(dateStr) : null;
  }

  static setPWAInstructionsShownDate(): void {
    // This can be a flag, but just in case i'll leave it as date if we decide to reset it at some point
    localStorage.setItem(
      Keys.PWA_INSTALL_INSTRUCTIONS_SHOWN,
      new Date().toISOString()
    );
  }

  static getPWAInstructionsShownDate(): Date | null {
    const dateStr = localStorage.getItem(Keys.PWA_INSTALL_INSTRUCTIONS_SHOWN);
    return dateStr ? new Date(dateStr) : null;
  }
}
