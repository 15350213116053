/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ParticipantAccountDetailsResponseResponse,
  ParticipantLoginRequestBody,
  ParticipantLoginResponseResponse,
  ParticipantOnboardRequestBody,
  ParticipantOnboardResponseResponse,
  ParticipantStudySupportedLanguagesResponseResponse,
  ParticipantUpdatePinRequestBody,
  ParticipantUpdatePinResponseResponse,
  ParticipantUpdatePreferredLanguageRequestBody,
  ParticipantUpdatePreferredLanguageResponseResponse,
} from "../../model/resources-participant.yml";
import type {
  BadRequestErrorResponseResponse,
  NotFoundErrorResponseResponse,
  ServerErrorResponseResponse,
  UnauthorizedResponseResponse,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔓 You can only access this endpoint as a guest.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the requested user with an access and refresh token.

 * @summary Login as a participant with credentials.
 */
export const loginAsParticipant = (
  participantLoginRequestBody: ParticipantLoginRequestBody,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantLoginResponseResponse>(
    {
      url: `/participant/auth/login`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantLoginRequestBody,
      signal,
    },
    options
  );
};

export const getLoginAsParticipantMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginAsParticipant>>,
    TError,
    { data: ParticipantLoginRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginAsParticipant>>,
  TError,
  { data: ParticipantLoginRequestBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginAsParticipant>>,
    { data: ParticipantLoginRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return loginAsParticipant(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginAsParticipantMutationResult = NonNullable<
  Awaited<ReturnType<typeof loginAsParticipant>>
>;
export type LoginAsParticipantMutationBody = ParticipantLoginRequestBody;
export type LoginAsParticipantMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Login as a participant with credentials.
 */
export const useLoginAsParticipant = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginAsParticipant>>,
    TError,
    { data: ParticipantLoginRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof loginAsParticipant>>,
  TError,
  { data: ParticipantLoginRequestBody },
  TContext
> => {
  const mutationOptions = getLoginAsParticipantMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔓 You can only access this endpoint as a guest.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the requested participant.

 * @summary Onboard a participant with credentials (screeningNumber or randomisationNumber), YoB, and PIN.
 */
export const onboardAParticipant = (
  participantOnboardRequestBody: ParticipantOnboardRequestBody,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantOnboardResponseResponse>(
    {
      url: `/participant/auth/onboard`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantOnboardRequestBody,
      signal,
    },
    options
  );
};

export const getOnboardAParticipantMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof onboardAParticipant>>,
    TError,
    { data: ParticipantOnboardRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof onboardAParticipant>>,
  TError,
  { data: ParticipantOnboardRequestBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof onboardAParticipant>>,
    { data: ParticipantOnboardRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return onboardAParticipant(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OnboardAParticipantMutationResult = NonNullable<
  Awaited<ReturnType<typeof onboardAParticipant>>
>;
export type OnboardAParticipantMutationBody = ParticipantOnboardRequestBody;
export type OnboardAParticipantMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Onboard a participant with credentials (screeningNumber or randomisationNumber), YoB, and PIN.
 */
export const useOnboardAParticipant = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof onboardAParticipant>>,
    TError,
    { data: ParticipantOnboardRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof onboardAParticipant>>,
  TError,
  { data: ParticipantOnboardRequestBody },
  TContext
> => {
  const mutationOptions = getOnboardAParticipantMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the updated participant.
- ‼️ You can not set a pin which includes the participant's YoB.
- ‼️ You can not set a new pin which is equal to your current pin.

 * @summary Allow an authenticated participant to update their pin by sending their current pin and new pin.
 */
export const updateAParticipantPin = (
  participantUpdatePinRequestBody: ParticipantUpdatePinRequestBody,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantUpdatePinResponseResponse>(
    {
      url: `/participant/auth/update-pin`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantUpdatePinRequestBody,
      signal,
    },
    options
  );
};

export const getUpdateAParticipantPinMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAParticipantPin>>,
    TError,
    { data: ParticipantUpdatePinRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAParticipantPin>>,
  TError,
  { data: ParticipantUpdatePinRequestBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAParticipantPin>>,
    { data: ParticipantUpdatePinRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateAParticipantPin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAParticipantPinMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAParticipantPin>>
>;
export type UpdateAParticipantPinMutationBody = ParticipantUpdatePinRequestBody;
export type UpdateAParticipantPinMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Allow an authenticated participant to update their pin by sending their current pin and new pin.
 */
export const useUpdateAParticipantPin = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAParticipantPin>>,
    TError,
    { data: ParticipantUpdatePinRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAParticipantPin>>,
  TError,
  { data: ParticipantUpdatePinRequestBody },
  TContext
> => {
  const mutationOptions = getUpdateAParticipantPinMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the updated participant.
- ‼️ You can only select a language which is supported by the participant's study.

 * @summary Allow an authenticated participant to update their preferred language.
 */
export const updateAParticipantPreferredLanguage = (
  participantUpdatePreferredLanguageRequestBody: ParticipantUpdatePreferredLanguageRequestBody,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantUpdatePreferredLanguageResponseResponse>(
    {
      url: `/participant/auth/update-preferred-language`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantUpdatePreferredLanguageRequestBody,
      signal,
    },
    options
  );
};

export const getUpdateAParticipantPreferredLanguageMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>,
    TError,
    { data: ParticipantUpdatePreferredLanguageRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>,
  TError,
  { data: ParticipantUpdatePreferredLanguageRequestBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>,
    { data: ParticipantUpdatePreferredLanguageRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateAParticipantPreferredLanguage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAParticipantPreferredLanguageMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>
>;
export type UpdateAParticipantPreferredLanguageMutationBody =
  ParticipantUpdatePreferredLanguageRequestBody;
export type UpdateAParticipantPreferredLanguageMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Allow an authenticated participant to update their preferred language.
 */
export const useUpdateAParticipantPreferredLanguage = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>,
    TError,
    { data: ParticipantUpdatePreferredLanguageRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAParticipantPreferredLanguage>>,
  TError,
  { data: ParticipantUpdatePreferredLanguageRequestBody },
  TContext
> => {
  const mutationOptions =
    getUpdateAParticipantPreferredLanguageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the authenticated participant's entity.

 * @summary Allow a participant to retrieve their account details.
 */
export const getParticipantAccountDetails = (
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantAccountDetailsResponseResponse>(
    { url: `/participant/auth/who`, method: "GET", signal },
    options
  );
};

export const getGetParticipantAccountDetailsQueryKey = () => {
  return [`/participant/auth/who`] as const;
};

export const getGetParticipantAccountDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getParticipantAccountDetails>>,
  TError = UnauthorizedResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantAccountDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetParticipantAccountDetailsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getParticipantAccountDetails>>
  > = ({ signal }) => getParticipantAccountDetails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getParticipantAccountDetails>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetParticipantAccountDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getParticipantAccountDetails>>
>;
export type GetParticipantAccountDetailsQueryError =
  UnauthorizedResponseResponse;

export function useGetParticipantAccountDetails<
  TData = Awaited<ReturnType<typeof getParticipantAccountDetails>>,
  TError = UnauthorizedResponseResponse
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantAccountDetails>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getParticipantAccountDetails>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetParticipantAccountDetails<
  TData = Awaited<ReturnType<typeof getParticipantAccountDetails>>,
  TError = UnauthorizedResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantAccountDetails>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getParticipantAccountDetails>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetParticipantAccountDetails<
  TData = Awaited<ReturnType<typeof getParticipantAccountDetails>>,
  TError = UnauthorizedResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantAccountDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Allow a participant to retrieve their account details.
 */

export function useGetParticipantAccountDetails<
  TData = Awaited<ReturnType<typeof getParticipantAccountDetails>>,
  TError = UnauthorizedResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantAccountDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetParticipantAccountDetailsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 No permissions are required for this endpoint.

# Extra information
- This endpoint will return the list of supported languages.

 * @summary Get the supported languages of a participant's current study
 */
export const getParticipantStudyAllowedLanguages = (
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantStudySupportedLanguagesResponseResponse>(
    { url: `/participant/studies/languages`, method: "GET", signal },
    options
  );
};

export const getGetParticipantStudyAllowedLanguagesQueryKey = () => {
  return [`/participant/studies/languages`] as const;
};

export const getGetParticipantStudyAllowedLanguagesQueryOptions = <
  TData = Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetParticipantStudyAllowedLanguagesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>
  > = ({ signal }) =>
    getParticipantStudyAllowedLanguages(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetParticipantStudyAllowedLanguagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>
>;
export type GetParticipantStudyAllowedLanguagesQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useGetParticipantStudyAllowedLanguages<
  TData = Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetParticipantStudyAllowedLanguages<
  TData = Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetParticipantStudyAllowedLanguages<
  TData = Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get the supported languages of a participant's current study
 */

export function useGetParticipantStudyAllowedLanguages<
  TData = Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getParticipantStudyAllowedLanguages>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getGetParticipantStudyAllowedLanguagesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
