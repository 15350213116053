import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVisits } from "../common/useVisits";
import StorageManager from "../services/storage";
import { dateString } from "../types/common";
import CardContainer from "./CardContainer";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";

const VisitsPage: React.FC = () => {
  const { t } = useTranslation();

  const { visits, isLoading } = useVisits();

  // useEffect(() => {
  //   const index = visits.findIndex(
  //     (visit) => new Date(visit.earliest_date) >= new Date()
  //   );
  //   setUpcomingIndex(index);
  // }, [visits.length]);

  function isPast(visit: VisitJsonApiBlockWithId): boolean {
    return false;
  }

  function loadingSkeleton() {
    return (
      <Timeline
        data-test-id="loading-timeline"
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {Array.from(new Array(3)).map((_, index) => (
          <TimelineItem key={`loading-${index}`}>
            <TimelineSeparator>
              <Skeleton variant="circular" width={24} height={24} />
            </TimelineSeparator>
            <TimelineContent>
              <Skeleton variant="text" width="50%" height={24} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }

  function content() {
    return (
      <Timeline
        data-test-id="visits-timeline"
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {visits.map((visit, index) => (
          <TimelineItem data-test-id="visit" key={visit.id}>
            <TimelineSeparator>
              <TimelineConnector
                sx={index === 0 ? { visibility: "hidden" } : null}
              />
              <TimelineDot>
                <CalendarTodayIcon sx={{ p: 0.5 }} />
              </TimelineDot>
              <TimelineConnector
                sx={
                  index === visits.length - 1 ? { visibility: "hidden" } : null
                }
              />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "12px", px: 2, my: "auto" }}>
              <Typography
                data-test-id="visit-name"
                variant="h6"
                component="span"
              >
                {visit.attributes.name}
              </Typography>

              <Typography data-test-id="visit-date">
                {dateString(visit)}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }

  return (
    <CardContainer showBorder={true} isLoading={isLoading}>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {t("visits_scheduleTitle")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        {isLoading ? loadingSkeleton() : content()}
      </CardContainer.Content>
    </CardContainer>
  );
};

export default VisitsPage;
