/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ExpenseCreateRequestBody,
  ExpenseCreateResponseResponse,
  ExpenseDeleteResponseResponse,
  ExpenseIndexResponseResponse,
  ExpenseReadResponseResponse,
  ExpenseRestoreResponseResponse,
  ExpenseUpdateRequestBody,
  ExpenseUpdateResponseResponse,
} from "../../model/resources-expenses.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewExpenseParams,
  ListAllExpensesParams,
  NotFoundErrorResponseResponse,
  ReadExpenseByIdParams,
  RestoreExpenseByIdParams,
  ServerErrorResponseResponse,
  UpdateExpenseByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.index` permission.

# Extra information
- This endpoint will return a paginated list of expenses.

 * @summary List all expenses.
 */
export const listAllExpenses = (
  params?: ListAllExpensesParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseIndexResponseResponse>(
    { url: `/expenses`, method: "GET", params, signal },
    options
  );
};

export const getListAllExpensesQueryKey = (params?: ListAllExpensesParams) => {
  return [`/expenses`, ...(params ? [params] : [])] as const;
};

export const getListAllExpensesQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllExpenses>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenses>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllExpensesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllExpenses>>> = ({
    signal,
  }) => listAllExpenses(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllExpenses>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllExpensesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllExpenses>>
>;
export type ListAllExpensesQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllExpenses<
  TData = Awaited<ReturnType<typeof listAllExpenses>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllExpensesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenses>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenses>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllExpenses<
  TData = Awaited<ReturnType<typeof listAllExpenses>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenses>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenses>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllExpenses<
  TData = Awaited<ReturnType<typeof listAllExpenses>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenses>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all expenses.
 */

export function useListAllExpenses<
  TData = Awaited<ReturnType<typeof listAllExpenses>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenses>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllExpensesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.create` permission.

# Extra information
- This endpoint will return the newly created expense.

 * @summary Create a new expense.
 */
export const createNewExpense = (
  expenseCreateRequestBody: ExpenseCreateRequestBody,
  params?: CreateNewExpenseParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseCreateResponseResponse>(
    {
      url: `/expenses`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewExpenseMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpense>>,
    TError,
    { data: ExpenseCreateRequestBody; params?: CreateNewExpenseParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewExpense>>,
  TError,
  { data: ExpenseCreateRequestBody; params?: CreateNewExpenseParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewExpense>>,
    { data: ExpenseCreateRequestBody; params?: CreateNewExpenseParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewExpense(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewExpenseMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewExpense>>
>;
export type CreateNewExpenseMutationBody = ExpenseCreateRequestBody;
export type CreateNewExpenseMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new expense.
 */
export const useCreateNewExpense = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpense>>,
    TError,
    { data: ExpenseCreateRequestBody; params?: CreateNewExpenseParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewExpense>>,
  TError,
  { data: ExpenseCreateRequestBody; params?: CreateNewExpenseParams },
  TContext
> => {
  const mutationOptions = getCreateNewExpenseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.read` permission.

# Extra information
- This endpoint will the requested expense.

 * @summary Read expense by ID.
 */
export const readExpenseById = (
  id: string,
  params?: ReadExpenseByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseReadResponseResponse>(
    { url: `/expenses/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadExpenseByIdQueryKey = (
  id: string,
  params?: ReadExpenseByIdParams
) => {
  return [`/expenses/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadExpenseByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readExpenseById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadExpenseByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readExpenseById>>> = ({
    signal,
  }) => readExpenseById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readExpenseById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadExpenseByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readExpenseById>>
>;
export type ReadExpenseByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadExpenseById<
  TData = Awaited<ReturnType<typeof readExpenseById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadExpenseByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadExpenseById<
  TData = Awaited<ReturnType<typeof readExpenseById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadExpenseById<
  TData = Awaited<ReturnType<typeof readExpenseById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read expense by ID.
 */

export function useReadExpenseById<
  TData = Awaited<ReturnType<typeof readExpenseById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadExpenseByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated expense.

 * @summary Update expense by ID.
 */
export const updateExpenseById = (
  id: string,
  expenseUpdateRequestBody: ExpenseUpdateRequestBody,
  params?: UpdateExpenseByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseUpdateResponseResponse>(
    {
      url: `/expenses/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateExpenseByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseById>>,
    TError,
    {
      id: string;
      data: ExpenseUpdateRequestBody;
      params?: UpdateExpenseByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateExpenseById>>,
  TError,
  {
    id: string;
    data: ExpenseUpdateRequestBody;
    params?: UpdateExpenseByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateExpenseById>>,
    {
      id: string;
      data: ExpenseUpdateRequestBody;
      params?: UpdateExpenseByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateExpenseById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateExpenseByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateExpenseById>>
>;
export type UpdateExpenseByIdMutationBody = ExpenseUpdateRequestBody;
export type UpdateExpenseByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update expense by ID.
 */
export const useUpdateExpenseById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseById>>,
    TError,
    {
      id: string;
      data: ExpenseUpdateRequestBody;
      params?: UpdateExpenseByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateExpenseById>>,
  TError,
  {
    id: string;
    data: ExpenseUpdateRequestBody;
    params?: UpdateExpenseByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateExpenseByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a expense by ID.
 */
export const deleteExpenseById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseDeleteResponseResponse>(
    { url: `/expenses/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteExpenseByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteExpenseById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteExpenseById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteExpenseById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteExpenseByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteExpenseById>>
>;

export type DeleteExpenseByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a expense by ID.
 */
export const useDeleteExpenseById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteExpenseById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteExpenseByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expenses.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored expense.

 * @summary Restore a expense by ID.
 */
export const restoreExpenseById = (
  id: string,
  params?: RestoreExpenseByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseRestoreResponseResponse>(
    { url: `/expenses/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreExpenseByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseById>>,
    TError,
    { id: string; params?: RestoreExpenseByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreExpenseById>>,
  TError,
  { id: string; params?: RestoreExpenseByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreExpenseById>>,
    { id: string; params?: RestoreExpenseByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreExpenseById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreExpenseByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreExpenseById>>
>;

export type RestoreExpenseByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a expense by ID.
 */
export const useRestoreExpenseById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseById>>,
    TError,
    { id: string; params?: RestoreExpenseByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreExpenseById>>,
  TError,
  { id: string; params?: RestoreExpenseByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreExpenseByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
