import { Box, Skeleton } from "@mui/material";
import React from "react";

const TaxFormsSkeleton: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "flex-start",
            sm: "flex-end",
          },
        }}
      >
        <Skeleton
          variant="rounded"
          width={165}
          height={42}
          sx={{ mb: "16px" }}
        />
      </Box>

      <Box my="20px auto" p="20px" border="1px solid #ccc" borderRadius="4px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box>
            <Skeleton width="150px" />
            <Skeleton width="200px" />
          </Box>
          <Skeleton variant="rectangular" width="200px" height={40} />
        </Box>
        <Box mt={2} py={2} borderTop="1px solid #e0e0e0">
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Skeleton width="100px" />
            <Skeleton width="100px" />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton width="250px" />
            <Skeleton variant="rounded" width="80px" height={30} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TaxFormsSkeleton;
