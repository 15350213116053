import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import CardContainer from "./CardContainer";
import { formatKeyText } from "../common/helpers";

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: any;
}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box padding={1}>
      <Typography data-test-id={label} variant="body1" fontWeight={"bold"}>
        {label}
      </Typography>
      <Typography data-test-id={`${label}-value`} variant="body2">
        {children}
      </Typography>
    </Box>
  </Grid>
);

export default function TrialDetailsPage() {
  const { t } = useTranslation();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const site = useSelector((state: RootState) => state.auth.site);
  const study = useSelector((state: RootState) => state.auth.study);

  if (!profile) throw Error("User data is missing");
  if (!site) throw Error("Site data is missing");
  if (!study) throw Error("Study data is missing");

  function getFullAddress(): string {
    const { line1, line2, line3, town, postcode } = site!.attributes;

    // Filter out null or empty values and join with a comma
    const formattedAddress = [line1, line2, line3, town, postcode]
      .filter((line) => line && line.trim().length > 0)
      .join(", ");

    return formattedAddress || "Address not available";
  }

  return (
    <CardContainer>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {study.attributes.name}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("visits_trialSubtitle")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <Grid container spacing={2}>
          <ValueWithLabel label={t("auth_participantIdNumber")}>
            {profile.participant_number}
          </ValueWithLabel>

          <ValueWithLabel label={t("trialDetails_studyNumber")}>
            {site.attributes.number}
          </ValueWithLabel>

          <ValueWithLabel label={t("trialDetails_phase")}>
            {formatKeyText(study.attributes.phase)}
          </ValueWithLabel>
          <ValueWithLabel label={t("trialDetails_sponsor")}>
            {study.attributes.sponsor}
          </ValueWithLabel>
          <ValueWithLabel label={t("trialDetails_primaryContactName")}>
            {site.attributes.pointOfContactName}
          </ValueWithLabel>
          <ValueWithLabel label={t("trialDetails_primaryContactPhone")}>
            {site.attributes.pointOfContactPhoneNumber}
          </ValueWithLabel>
          <ValueWithLabel label={t("trialDetails_primaryContactEmail")}>
            {site.attributes.pointOfContactEmail}
          </ValueWithLabel>
          <ValueWithLabel label={t("trialDetails_location")}>
            {getFullAddress()}
          </ValueWithLabel>
        </Grid>
        {study.attributes.description && (
          <ValueWithLabel label={t("trialDetails_description")}>
            {study.attributes.description}
          </ValueWithLabel>
        )}
      </CardContainer.Content>
    </CardContainer>
  );
}
