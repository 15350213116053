/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ExpenseItemCreateRequestBody,
  ExpenseItemCreateResponseResponse,
  ExpenseItemDeleteResponseResponse,
  ExpenseItemIndexResponseResponse,
  ExpenseItemReadResponseResponse,
  ExpenseItemRestoreResponseResponse,
  ExpenseItemUpdateRequestBody,
  ExpenseItemUpdateResponseResponse,
} from "../../model/resources-expense-items.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewExpenseItemParams,
  ListAllExpenseItemsParams,
  NotFoundErrorResponseResponse,
  ReadExpenseItemByIdParams,
  RestoreExpenseItemByIdParams,
  ServerErrorResponseResponse,
  UpdateExpenseItemByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.index` permission.

# Extra information
- This endpoint will return a paginated list of expense-items.

 * @summary List all expense-items.
 */
export const listAllExpenseItems = (
  params?: ListAllExpenseItemsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseItemIndexResponseResponse>(
    { url: `/expense-items`, method: "GET", params, signal },
    options
  );
};

export const getListAllExpenseItemsQueryKey = (
  params?: ListAllExpenseItemsParams
) => {
  return [`/expense-items`, ...(params ? [params] : [])] as const;
};

export const getListAllExpenseItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllExpenseItems>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseItems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllExpenseItemsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAllExpenseItems>>
  > = ({ signal }) => listAllExpenseItems(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllExpenseItems>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllExpenseItemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllExpenseItems>>
>;
export type ListAllExpenseItemsQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllExpenseItems<
  TData = Awaited<ReturnType<typeof listAllExpenseItems>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllExpenseItemsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseItems>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenseItems>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllExpenseItems<
  TData = Awaited<ReturnType<typeof listAllExpenseItems>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseItems>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenseItems>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllExpenseItems<
  TData = Awaited<ReturnType<typeof listAllExpenseItems>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseItems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all expense-items.
 */

export function useListAllExpenseItems<
  TData = Awaited<ReturnType<typeof listAllExpenseItems>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseItems>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllExpenseItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.create` permission.

# Extra information
- This endpoint will return the newly created expense item.

 * @summary Create a new expense item.
 */
export const createNewExpenseItem = (
  expenseItemCreateRequestBody: ExpenseItemCreateRequestBody,
  params?: CreateNewExpenseItemParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseItemCreateResponseResponse>(
    {
      url: `/expense-items`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseItemCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewExpenseItemMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpenseItem>>,
    TError,
    { data: ExpenseItemCreateRequestBody; params?: CreateNewExpenseItemParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewExpenseItem>>,
  TError,
  { data: ExpenseItemCreateRequestBody; params?: CreateNewExpenseItemParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewExpenseItem>>,
    { data: ExpenseItemCreateRequestBody; params?: CreateNewExpenseItemParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewExpenseItem(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewExpenseItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewExpenseItem>>
>;
export type CreateNewExpenseItemMutationBody = ExpenseItemCreateRequestBody;
export type CreateNewExpenseItemMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new expense item.
 */
export const useCreateNewExpenseItem = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpenseItem>>,
    TError,
    { data: ExpenseItemCreateRequestBody; params?: CreateNewExpenseItemParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewExpenseItem>>,
  TError,
  { data: ExpenseItemCreateRequestBody; params?: CreateNewExpenseItemParams },
  TContext
> => {
  const mutationOptions = getCreateNewExpenseItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.read` permission.

# Extra information
- This endpoint will the requested expense type.

 * @summary Read expense-item by ID.
 */
export const readExpenseItemById = (
  id: string,
  params?: ReadExpenseItemByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseItemReadResponseResponse>(
    { url: `/expense-items/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadExpenseItemByIdQueryKey = (
  id: string,
  params?: ReadExpenseItemByIdParams
) => {
  return [`/expense-items/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadExpenseItemByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readExpenseItemById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseItemByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseItemById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadExpenseItemByIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof readExpenseItemById>>
  > = ({ signal }) => readExpenseItemById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readExpenseItemById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadExpenseItemByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readExpenseItemById>>
>;
export type ReadExpenseItemByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadExpenseItemById<
  TData = Awaited<ReturnType<typeof readExpenseItemById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadExpenseItemByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseItemById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseItemById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadExpenseItemById<
  TData = Awaited<ReturnType<typeof readExpenseItemById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseItemByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseItemById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseItemById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadExpenseItemById<
  TData = Awaited<ReturnType<typeof readExpenseItemById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseItemByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseItemById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read expense-item by ID.
 */

export function useReadExpenseItemById<
  TData = Awaited<ReturnType<typeof readExpenseItemById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseItemByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseItemById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadExpenseItemByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated expense type.

 * @summary Update expense-item by ID.
 */
export const updateExpenseItemById = (
  id: string,
  expenseItemUpdateRequestBody: ExpenseItemUpdateRequestBody,
  params?: UpdateExpenseItemByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseItemUpdateResponseResponse>(
    {
      url: `/expense-items/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseItemUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateExpenseItemByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseItemById>>,
    TError,
    {
      id: string;
      data: ExpenseItemUpdateRequestBody;
      params?: UpdateExpenseItemByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateExpenseItemById>>,
  TError,
  {
    id: string;
    data: ExpenseItemUpdateRequestBody;
    params?: UpdateExpenseItemByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateExpenseItemById>>,
    {
      id: string;
      data: ExpenseItemUpdateRequestBody;
      params?: UpdateExpenseItemByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateExpenseItemById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateExpenseItemByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateExpenseItemById>>
>;
export type UpdateExpenseItemByIdMutationBody = ExpenseItemUpdateRequestBody;
export type UpdateExpenseItemByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update expense-item by ID.
 */
export const useUpdateExpenseItemById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseItemById>>,
    TError,
    {
      id: string;
      data: ExpenseItemUpdateRequestBody;
      params?: UpdateExpenseItemByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateExpenseItemById>>,
  TError,
  {
    id: string;
    data: ExpenseItemUpdateRequestBody;
    params?: UpdateExpenseItemByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateExpenseItemByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a expense-item by ID.
 */
export const deleteExpenseItemById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseItemDeleteResponseResponse>(
    { url: `/expense-items/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteExpenseItemByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseItemById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteExpenseItemById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteExpenseItemById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteExpenseItemById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteExpenseItemByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteExpenseItemById>>
>;

export type DeleteExpenseItemByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a expense-item by ID.
 */
export const useDeleteExpenseItemById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseItemById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteExpenseItemById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteExpenseItemByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-items.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored expense type.

 * @summary Restore a expense-item by ID.
 */
export const restoreExpenseItemById = (
  id: string,
  params?: RestoreExpenseItemByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseItemRestoreResponseResponse>(
    { url: `/expense-items/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreExpenseItemByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseItemById>>,
    TError,
    { id: string; params?: RestoreExpenseItemByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreExpenseItemById>>,
  TError,
  { id: string; params?: RestoreExpenseItemByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreExpenseItemById>>,
    { id: string; params?: RestoreExpenseItemByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreExpenseItemById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreExpenseItemByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreExpenseItemById>>
>;

export type RestoreExpenseItemByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a expense-item by ID.
 */
export const useRestoreExpenseItemById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseItemById>>,
    TError,
    { id: string; params?: RestoreExpenseItemByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreExpenseItemById>>,
  TError,
  { id: string; params?: RestoreExpenseItemByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreExpenseItemByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
