import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Alert, AlertTitle, Button } from "@mui/material";
import CardContainer from "../CardContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TaxFormJsonApiBlockWithId } from "../../api/model/resources-tax-forms.yml";

interface Props {
  taxForms: TaxFormJsonApiBlockWithId[];
  hasPendingStipends: Boolean;
}

export default function TaxAlert({ taxForms, hasPendingStipends }: Props) {
  const profile = useSelector((state: RootState) => state.auth.profile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (!error) {
  //     return;
  //   }

  //   handleServerError(error);
  // }, [error]);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!profile) return;

    const isUsUser = profile.country.toLowerCase() === "us";
    const submittedTaxForms = taxForms.length > 0;
    const showAlert = isUsUser && !submittedTaxForms;
    setShowAlert(showAlert);
  }, [taxForms, profile]);

  if (!showAlert) return null;

  return (
    <CardContainer>
      <CardContainer.Content>
        <Alert
          severity={hasPendingStipends ? "error" : "info"}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            border: "none",
            px: 0,
          }}
          action={
            <Button
              data-test-id="tax-alert-button"
              onClick={() => navigate("/tax")}
              variant="contained"
              sx={{
                fontWeight: "bold",
                minWidth: "7rem",
              }}
              size="medium"
            >
              {t("findOutMore")}
            </Button>
          }
        >
          <AlertTitle
            data-test-id="tax-alert-description"
            sx={{ fontWeight: "bold" }}
          >
            {hasPendingStipends
              ? t("tax_required_msg")
              : t("tax_requiresTaxInfo")}
          </AlertTitle>
        </Alert>
      </CardContainer.Content>
    </CardContainer>
  );
}
