/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  TaxFormCreateRequestBody,
  TaxFormCreateResponseResponse,
  TaxFormDeleteResponseResponse,
  TaxFormIndexResponseResponse,
  TaxFormReadResponseResponse,
} from "../../model/resources-tax-forms.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateTaxFormParams,
  ListAllTaxFormsParams,
  NotFoundErrorResponseResponse,
  ReadTaxFormByIdParams,
  ServerErrorResponseResponse,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `tax-forms.create` permission.

# Extra information
- This endpoint will return the newly created tax form, with the url to access it.

 * @summary Create a new tax form.
 */
export const createTaxForm = (
  taxFormCreateRequestBody: TaxFormCreateRequestBody,
  params?: CreateTaxFormParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<TaxFormCreateResponseResponse>(
    {
      url: `/tax-forms`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: taxFormCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateTaxFormMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaxForm>>,
    TError,
    { data: TaxFormCreateRequestBody; params?: CreateTaxFormParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTaxForm>>,
  TError,
  { data: TaxFormCreateRequestBody; params?: CreateTaxFormParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTaxForm>>,
    { data: TaxFormCreateRequestBody; params?: CreateTaxFormParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createTaxForm(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTaxFormMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTaxForm>>
>;
export type CreateTaxFormMutationBody = TaxFormCreateRequestBody;
export type CreateTaxFormMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new tax form.
 */
export const useCreateTaxForm = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTaxForm>>,
    TError,
    { data: TaxFormCreateRequestBody; params?: CreateTaxFormParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTaxForm>>,
  TError,
  { data: TaxFormCreateRequestBody; params?: CreateTaxFormParams },
  TContext
> => {
  const mutationOptions = getCreateTaxFormMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `tax-forms.index` permission.

# Extra information
- This endpoint will return the requested tax form.

 * @summary List all tax forms.
 */
export const listAllTaxForms = (
  params?: ListAllTaxFormsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<TaxFormIndexResponseResponse>(
    { url: `/tax-forms`, method: "GET", params, signal },
    options
  );
};

export const getListAllTaxFormsQueryKey = (params?: ListAllTaxFormsParams) => {
  return [`/tax-forms`, ...(params ? [params] : [])] as const;
};

export const getListAllTaxFormsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllTaxForms>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  params?: ListAllTaxFormsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllTaxForms>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllTaxFormsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllTaxForms>>> = ({
    signal,
  }) => listAllTaxForms(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllTaxForms>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllTaxFormsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllTaxForms>>
>;
export type ListAllTaxFormsQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllTaxForms<
  TData = Awaited<ReturnType<typeof listAllTaxForms>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  params: undefined | ListAllTaxFormsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllTaxForms>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllTaxForms>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllTaxForms<
  TData = Awaited<ReturnType<typeof listAllTaxForms>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  params?: ListAllTaxFormsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllTaxForms>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllTaxForms>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllTaxForms<
  TData = Awaited<ReturnType<typeof listAllTaxForms>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  params?: ListAllTaxFormsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllTaxForms>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all tax forms.
 */

export function useListAllTaxForms<
  TData = Awaited<ReturnType<typeof listAllTaxForms>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  params?: ListAllTaxFormsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllTaxForms>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllTaxFormsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `tax-forms.read` permission.

# Extra information
- This endpoint will return the requested tax forms.

 * @summary Read tax form by ID.
 */
export const readTaxFormById = (
  id: string,
  params?: ReadTaxFormByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<TaxFormReadResponseResponse>(
    { url: `/tax-forms/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadTaxFormByIdQueryKey = (
  id: string,
  params?: ReadTaxFormByIdParams
) => {
  return [`/tax-forms/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadTaxFormByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readTaxFormById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadTaxFormByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readTaxFormById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadTaxFormByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readTaxFormById>>> = ({
    signal,
  }) => readTaxFormById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readTaxFormById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadTaxFormByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readTaxFormById>>
>;
export type ReadTaxFormByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadTaxFormById<
  TData = Awaited<ReturnType<typeof readTaxFormById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadTaxFormByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readTaxFormById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readTaxFormById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadTaxFormById<
  TData = Awaited<ReturnType<typeof readTaxFormById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadTaxFormByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readTaxFormById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readTaxFormById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadTaxFormById<
  TData = Awaited<ReturnType<typeof readTaxFormById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadTaxFormByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readTaxFormById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read tax form by ID.
 */

export function useReadTaxFormById<
  TData = Awaited<ReturnType<typeof readTaxFormById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadTaxFormByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readTaxFormById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadTaxFormByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `tax-form.delete` permission.

# Extra information
- This endpoint will return an empty response body.

 * @summary Delete tax form by ID.
 */
export const deleteTaxFormById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<TaxFormDeleteResponseResponse>(
    { url: `/tax-forms/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteTaxFormByIdMutationOptions = <
  TError = NotFoundErrorResponseResponse | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTaxFormById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTaxFormById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTaxFormById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteTaxFormById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTaxFormByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTaxFormById>>
>;

export type DeleteTaxFormByIdMutationError =
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete tax form by ID.
 */
export const useDeleteTaxFormById = <
  TError = NotFoundErrorResponseResponse | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTaxFormById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTaxFormById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteTaxFormByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
