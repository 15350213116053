import ErrorBase from "./ErrorBase";

export class GeneralError extends ErrorBase {
  constructor(message: string = "error_somethingWentWrong", stack?: string) {
    super({
      name: "GeneralError",
      title: "error",
      message: message,
      icon: "/images/server_warning.svg",
      stack: stack,
    });
    Object.setPrototypeOf(this, GeneralError.prototype);
  }
}
