export const getStatusStyles = (status: string, theme: any) => {
  switch (status.toLowerCase()) {
    case "pending":
    case "sent":
      return {
        backgroundColor: "rgba(33, 150, 243, 0.15)", // Blue with opacity
        color: theme.palette.primary.main,
      };
    case "in Progress":
      return {
        backgroundColor: "rgba(255, 152, 0, 0.15)", // Orange with opacity
        color: theme.palette.warning.main,
      };
    case "invalid":
      return {
        backgroundColor: "rgba(244, 67, 54, 0.15)", // Red with opacity
        color: theme.palette.error.main,
      };
    case "completed":
      return {
        backgroundColor: "rgba(76, 175, 80, 0.15)", // Green with opacity
        color: theme.palette.success.main,
      };
    default:
      return {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
      };
  }
};
