import { List } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExpenseListItem from "../components/ExpenseListItem";
import { ExpenseRowData } from "../types/common";

interface Props {
  data: ExpenseRowData[];
}

const ExpensesList: React.FC<Props> = ({ data }: Props) => {
  const navigate = useNavigate();

  const handleExpenseClick = (id: string) => {
    navigate(`/payment/${id}/details`);
    // navigate(`/expense/${id}`);
  };

  return (
    <List>
      {data.map((expense) => {
        return (
          <ExpenseListItem
            expense={expense}
            key={expense.id}
            onClick={() => handleExpenseClick(expense.id)}
          />
        );
      })}
    </List>
  );
};

export default ExpensesList;
