export default class ErrorBase extends Error {
  title: string;
  icon?: string;

  constructor({
    name,
    title,
    message,
    icon,
    stack,
  }: {
    name: string;
    title: string;
    message: string;
    icon?: string;
    stack?: string;
  }) {
    super();
    this.stack = stack;
    this.name = name;
    this.title = title;
    this.message = message;
    this.icon = icon;
  }
}
