/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  VisitCreateRequestBody,
  VisitCreateResponseResponse,
  VisitDeleteResponseResponse,
  VisitIndexResponseResponse,
  VisitReadResponseResponse,
  VisitRestoreResponseResponse,
  VisitUpdateRequestBody,
  VisitUpdateResponseResponse,
} from "../../model/resources-visits.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewVisitParams,
  ListAllVisitsParams,
  NotFoundErrorResponseResponse,
  ReadVisitByIdParams,
  RestoreVisitByIdParams,
  ServerErrorResponseResponse,
  UpdateVisitByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.index` permission.

# Extra information
- This endpoint will return a paginated list of visits.

 * @summary List all visits.
 */
export const listAllVisits = (
  params?: ListAllVisitsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<VisitIndexResponseResponse>(
    { url: `/visits`, method: "GET", params, signal },
    options
  );
};

export const getListAllVisitsQueryKey = (params?: ListAllVisitsParams) => {
  return [`/visits`, ...(params ? [params] : [])] as const;
};

export const getListAllVisitsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAllVisits>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllVisitsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllVisits>>> = ({
    signal,
  }) => listAllVisits(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllVisits>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllVisitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllVisits>>
>;
export type ListAllVisitsQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllVisits<
  TData = Awaited<ReturnType<typeof listAllVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllVisitsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAllVisits>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllVisits>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllVisits<
  TData = Awaited<ReturnType<typeof listAllVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAllVisits>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllVisits>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllVisits<
  TData = Awaited<ReturnType<typeof listAllVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAllVisits>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all visits.
 */

export function useListAllVisits<
  TData = Awaited<ReturnType<typeof listAllVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listAllVisits>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllVisitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.create` permission.

# Extra information
- This endpoint will return the newly created visit.

 * @summary Create a new visit.
 */
export const createNewVisit = (
  visitCreateRequestBody: VisitCreateRequestBody,
  params?: CreateNewVisitParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<VisitCreateResponseResponse>(
    {
      url: `/visits`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: visitCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewVisitMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewVisit>>,
    TError,
    { data: VisitCreateRequestBody; params?: CreateNewVisitParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewVisit>>,
  TError,
  { data: VisitCreateRequestBody; params?: CreateNewVisitParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewVisit>>,
    { data: VisitCreateRequestBody; params?: CreateNewVisitParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewVisit(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewVisitMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewVisit>>
>;
export type CreateNewVisitMutationBody = VisitCreateRequestBody;
export type CreateNewVisitMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new visit.
 */
export const useCreateNewVisit = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewVisit>>,
    TError,
    { data: VisitCreateRequestBody; params?: CreateNewVisitParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewVisit>>,
  TError,
  { data: VisitCreateRequestBody; params?: CreateNewVisitParams },
  TContext
> => {
  const mutationOptions = getCreateNewVisitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.read` permission.

# Extra information
- This endpoint will the requested visit.

 * @summary Read visit by ID.
 */
export const readVisitById = (
  id: string,
  params?: ReadVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<VisitReadResponseResponse>(
    { url: `/visits/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadVisitByIdQueryKey = (
  id: string,
  params?: ReadVisitByIdParams
) => {
  return [`/visits/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadVisitByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readVisitById>>,
  TError = unknown
>(
  id: string,
  params?: ReadVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof readVisitById>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadVisitByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readVisitById>>> = ({
    signal,
  }) => readVisitById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readVisitById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadVisitByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readVisitById>>
>;
export type ReadVisitByIdQueryError = unknown;

export function useReadVisitById<
  TData = Awaited<ReturnType<typeof readVisitById>>,
  TError = unknown
>(
  id: string,
  params: undefined | ReadVisitByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof readVisitById>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readVisitById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadVisitById<
  TData = Awaited<ReturnType<typeof readVisitById>>,
  TError = unknown
>(
  id: string,
  params?: ReadVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof readVisitById>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readVisitById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadVisitById<
  TData = Awaited<ReturnType<typeof readVisitById>>,
  TError = unknown
>(
  id: string,
  params?: ReadVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof readVisitById>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read visit by ID.
 */

export function useReadVisitById<
  TData = Awaited<ReturnType<typeof readVisitById>>,
  TError = unknown
>(
  id: string,
  params?: ReadVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof readVisitById>>, TError, TData>
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadVisitByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated visit.

 * @summary Update visit by ID.
 */
export const updateVisitById = (
  id: string,
  visitUpdateRequestBody: VisitUpdateRequestBody,
  params?: UpdateVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<VisitUpdateResponseResponse>(
    {
      url: `/visits/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: visitUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateVisitByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVisitById>>,
    TError,
    {
      id: string;
      data: VisitUpdateRequestBody;
      params?: UpdateVisitByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateVisitById>>,
  TError,
  { id: string; data: VisitUpdateRequestBody; params?: UpdateVisitByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateVisitById>>,
    { id: string; data: VisitUpdateRequestBody; params?: UpdateVisitByIdParams }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateVisitById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateVisitById>>
>;
export type UpdateVisitByIdMutationBody = VisitUpdateRequestBody;
export type UpdateVisitByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update visit by ID.
 */
export const useUpdateVisitById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVisitById>>,
    TError,
    {
      id: string;
      data: VisitUpdateRequestBody;
      params?: UpdateVisitByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateVisitById>>,
  TError,
  { id: string; data: VisitUpdateRequestBody; params?: UpdateVisitByIdParams },
  TContext
> => {
  const mutationOptions = getUpdateVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a visit by ID.
 */
export const deleteVisitById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<VisitDeleteResponseResponse>(
    { url: `/visits/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteVisitByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteVisitById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteVisitById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteVisitById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteVisitById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteVisitById>>
>;

export type DeleteVisitByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a visit by ID.
 */
export const useDeleteVisitById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteVisitById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteVisitById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `visits.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored visit.

 * @summary Restore a visit by ID.
 */
export const restoreVisitById = (
  id: string,
  params?: RestoreVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<VisitRestoreResponseResponse>(
    { url: `/visits/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreVisitByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreVisitById>>,
    TError,
    { id: string; params?: RestoreVisitByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreVisitById>>,
  TError,
  { id: string; params?: RestoreVisitByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreVisitById>>,
    { id: string; params?: RestoreVisitByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreVisitById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreVisitById>>
>;

export type RestoreVisitByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a visit by ID.
 */
export const useRestoreVisitById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreVisitById>>,
    TError,
    { id: string; params?: RestoreVisitByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreVisitById>>,
  TError,
  { id: string; params?: RestoreVisitByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
