import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";

import { setVccPaymentData } from "../redux/slices/vccSlice";
import { RootState } from "../redux/store";
import StorageManager from "../services/storage";

import { cleanEmptyProperties } from "../types/common";
import {
  AvailablePaymentMethod,
  PaymentAccountDetails,
  PaymentMethodProp,
  PaymentType,
  PropertyClientType,
} from "../types/paymentTypes";

import { WhiteSecurityIcon } from "../common/constants";
import { usePageTitle } from "../common/TitleContext";

import CardContainer from "../pages/CardContainer";

import {
  validateEmail,
  validatePhone,
} from "../components/LoqateValidationUtils";
import {
  ValidationResponse,
  ValidationStatus,
} from "../components/ValidationTypes";

import AddressForm from "../components/PaymentMethods/AddressForm";
import DebitCardForm from "../components/PaymentMethods/DebitCardForm";
import FormErrorBox from "../components/PaymentMethods/FormErrorBox";
import FormProperty, {
  validateProperty,
} from "../components/PaymentMethods/FormProperty";
import { useErrorHandler } from "../common/hooks";
import { AxiosError } from "axios";
import moment from "moment";

declare global {
  interface Window {
    pca: any;
  }
}

const PaymentInputForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleServerError } = useErrorHandler();
  const { t } = useTranslation();
  const location = useLocation();

  // const { id: claimId, paymentMethodIndex: paymentMethodId } = useParams<{
  //   id: string;
  //   paymentMethodIndex: string;
  // }>();

  const selectedPaymentMethod: AvailablePaymentMethod = useSelector(
    (state: RootState) => {
      // const id = parseInt(paymentMethodId ?? "");
      const paymentMethods = state.paymentMethods.availablePaymentMethods;
      const selectedItem = paymentMethods.find(
        (x) => x.payment_type === "VIRTUAL_CARD"
      );

      if (selectedItem) {
        return selectedItem;
      } else {
        throw new Error(t("payment_method_not_available"));
      }
    }
  );

  usePageTitle(selectedPaymentMethod.title);

  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [propWithNestedProps, setPropWithNestedProps] =
    useState<PaymentMethodProp | null>(null);
  const [formState, setFormState] = useState<Record<string, string>>(
    StorageManager.getFormData() ?? {}
  );
  const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  const [encryptedCardDetails, setEncryptedCardDetails] =
    useState<any>(undefined);

  const propertiesWithNestedProps = useMemo(() => {
    return selectedPaymentMethod.properties.filter(
      (property: PaymentMethodProp) => property.type === "object"
    );
  }, [selectedPaymentMethod]);

  const formStepsCount = propertiesWithNestedProps.length;

  const currentFormStep =
    Number(new URLSearchParams(location.search).get("step")) || 0;

  useEffect(() => {
    if (currentFormStep > 0 && currentFormStep <= formStepsCount) {
      const selectedParentProperty =
        propertiesWithNestedProps[currentFormStep - 1] || {};

      setPropWithNestedProps(selectedParentProperty);
    }
  }, [currentFormStep, propertiesWithNestedProps]);

  // when continue button is pressed
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    StorageManager.setFormData(formState);

    let isAsyncValide = true;

    // Check if email needs to be validated
    if (getPropByClientType(PropertyClientType.Email)) {
      setIsLoading(true);

      const isEmailValid = await validateEmailDetails();

      isAsyncValide = isAsyncValide && isEmailValid;
    }

    // Check if phone needs to be validated
    if (getPropByClientType(PropertyClientType.Phone)) {
      setIsLoading(true);

      const isPhoneValid = await validatePhoneDetails();
      isAsyncValide = isAsyncValide && isPhoneValid;
    }

    setIsLoading(false);

    // Stop the form submission if any async validation failed.
    if (!isAsyncValide) {
      return;
    }

    if (currentFormStep < formStepsCount) {
      incrementStepAndNavigate();

      return;
    }

    const paymentData: { [key: string]: any } = {
      payment_country_iso: selectedPaymentMethod.country,
    };

    selectedPaymentMethod.properties.forEach((mainProp) => {
      if (mainProp.type == "object") {
        const propValues: { [key: string]: string } = {};

        mainProp.nestedProps?.forEach((nestedProp: PaymentMethodProp) => {
          if (nestedProp.client_type == PropertyClientType.Country) {
            propValues[nestedProp.property_name] =
              selectedPaymentMethod.country;
            return;
          }

          return (propValues[nestedProp.property_name] =
            formState[
              `${mainProp.property_name}.${nestedProp.property_name}`
            ].trim());
        });

        paymentData[mainProp.property_name] = cleanEmptyProperties(propValues);

        return;
      }

      if (mainProp.property_name == "card_number") {
        paymentData[mainProp.property_name] =
          encryptedCardDetails?.encryptedCard?.number;
        return;
      }

      if (mainProp.property_name == "expiration_date") {
        paymentData[mainProp.property_name] =
          20 +
          encryptedCardDetails?.encryptedCard?.expYear +
          "-" +
          encryptedCardDetails?.encryptedCard?.expMonth;
        return;
      }

      const isPropRequired =
        selectedPaymentMethod.required.indexOf(mainProp.property_name) > -1;

      if (isPropRequired) {
        paymentData[mainProp.property_name] =
          formState[mainProp.property_name].trim();
      } else if (formState[mainProp.property_name]?.trim()) {
        paymentData[mainProp.property_name] =
          formState[mainProp.property_name].trim();
      }

      if (mainProp.client_type == PropertyClientType.Date) {
        let dateInputFormated = moment(
          formState[mainProp.property_name]
        ).format("YYYY-MM-DD");
        paymentData[mainProp.property_name] = dateInputFormated;
      }
    });

    createPaymentAccount(paymentData);
  };

  // create a payment account using the paymentData
  const createPaymentAccount = async (paymentData: any) => {
    if (selectedPaymentMethod.payment_type === PaymentType.VirtualCard) {
      dispatch(
        setVccPaymentData({
          ...paymentData,
          endpoint: selectedPaymentMethod.endpoint,
        })
      );

      navigate(`/payment-methods/VIRTUAL_CARD/review`);

      return;
    }

    // try {
    //   setIsLoading(true);

    //   const response = await api<PaymentAccountDetails>(
    //     PaymentService.createPayment(
    //       paymentData,
    //       selectedPaymentMethod.endpoint
    //     )
    //   );

    //   dispatch(setLatestPaymentMethod(response.data));
    //   navigate(`/payment/submit/${claimId}/summary`);

    //   StorageManager.removeFormData();
    // } catch (error: any) {
    //   handleServerError(error as AxiosError);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  // when input change, it checks if there is an error and update the error state.
  // also it update the form state
  const handleInputChange =
    (propertyPath: string, property: PaymentMethodProp) => (event: any) => {
      const newValue = event.target.value;

      const validationResponse: ValidationResponse = validateProperty(
        property,
        newValue,
        selectedPaymentMethod.country
      );

      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [propertyPath]: validationResponse.errorMessage,
      }));

      setFormState((prevState) => ({
        ...prevState,
        [propertyPath]: newValue as string,
      }));
    };

  // check if the required fields are filled
  const requiredFieldsFilled = () => {
    if (
      selectedPaymentMethod.payment_type === PaymentType.DebitCard &&
      encryptedCardDetails?.isValid &&
      !!formState[selectedPaymentMethod.properties[0].property_name]
    ) {
      return true;
    }

    if (propWithNestedProps?.nestedProps?.length && currentFormStep) {
      return nestedRequiredFieldsFilled();
    }

    const topLevelPropertiesWithoutNested =
      selectedPaymentMethod?.required?.filter((reqField: string) => {
        return !selectedPaymentMethod.properties.some(
          (prop) => prop.property_name === reqField && prop.nestedProps
        );
      });

    return topLevelPropertiesWithoutNested.every(
      (reqField: string | number) => !!formState[reqField]
    );
  };

  // check if the nested form fields are filled
  const nestedRequiredFieldsFilled = () => {
    if (!propWithNestedProps?.nestedProps?.length) {
      return true;
    }

    return propWithNestedProps.nestedProps.every((nestedProp) => {
      if (nestedProp.client_type == PropertyClientType.Country) {
        return true;
      }

      const formValue =
        formState[
          `${propWithNestedProps.property_name}.${nestedProp.property_name}`
        ];

      return !!formValue;
    });
  };

  const validateEmailDetails = async () => {
    const propertyByType = getPropByClientType(PropertyClientType.Email);

    if (!propertyByType?.property_name) {
      return true;
    }

    const email = formState[propertyByType.property_name];

    if (!email) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [propertyByType.property_name]: t("error_email"),
      }));

      return false;
    }

    const emailValidationStatus = await validateEmail(email);

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [propertyByType.property_name]:
        emailValidationStatus === ValidationStatus.VALID
          ? ""
          : t("error_email"),
    }));

    return emailValidationStatus === ValidationStatus.VALID;
  };

  const validatePhoneDetails = async () => {
    const propertyByType = getPropByClientType(PropertyClientType.Phone);

    if (!propertyByType?.property_name) {
      return true;
    }

    const phone = formState[propertyByType.property_name];

    if (!phone) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [propertyByType.property_name]: t("error_invalidPhone"),
      }));

      return false;
    }

    const phoneValidationStatus = await validatePhone(
      phone,
      selectedPaymentMethod.country
    );

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [propertyByType.property_name]:
        phoneValidationStatus === ValidationStatus.VALID
          ? ""
          : t("error_invalidPhone"),
    }));

    return phoneValidationStatus === ValidationStatus.VALID;
  };

  const getPropByClientType = (type: PropertyClientType) => {
    return selectedPaymentMethod.properties.find(
      (prop: PaymentMethodProp) => prop.client_type == type
    );
  };

  const incrementStepAndNavigate = () => {
    const nextStep = currentFormStep + 1;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("step", nextStep.toString());

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const renderNestedForm = () => {
    if (!propWithNestedProps || !propWithNestedProps?.nestedProps?.length) {
      return null;
    }

    if (propWithNestedProps.client_type === PropertyClientType.Address) {
      return (
        <AddressForm
          paymentMethod={selectedPaymentMethod}
          property={propWithNestedProps}
          formState={formState}
          handleInputChange={handleInputChange}
          errors={inputErrors}
        />
      );
    } else {
      let result = propWithNestedProps.nestedProps
        .filter(
          (nestedProp) => nestedProp.client_type != PropertyClientType.Country
        )
        .map((nestedProp, index) => {
          const nestedPropPath = `${propWithNestedProps.property_name}.${nestedProp.property_name}`;

          return renderPropertyInput(nestedProp, nestedPropPath, index);
        });

      return result;
    }
  };

  const renderPropertyInput = (
    property: PaymentMethodProp,
    path: string,
    index: number
  ) => {
    return (
      <Grid mb={2} item key={index}>
        <FormProperty
          property={property}
          propertyPath={path}
          paymentMethod={selectedPaymentMethod}
          formState={formState}
          handleInputChange={handleInputChange}
          errors={inputErrors}
        />
      </Grid>
    );
  };

  const isSubmitDisabled = React.useMemo(() => {
    let result =
      !requiredFieldsFilled() ||
      Object.keys(inputErrors).filter((key) => inputErrors[key] !== "").length >
        0;
    return result;
  }, [requiredFieldsFilled, inputErrors]);

  const nextStepType = useMemo(() => {
    if (formStepsCount > 0 && currentFormStep < formStepsCount) {
      const futureStepProperty =
        propertiesWithNestedProps[currentFormStep] || {};

      return (futureStepProperty?.client_type as PropertyClientType) || null;
    }

    return null;
  }, [propertiesWithNestedProps, currentFormStep]);

  const getButtonTitle = () => {
    if (
      selectedPaymentMethod.payment_type === PaymentType.VirtualCard &&
      currentFormStep === formStepsCount
    ) {
      return t("vcc_review");
    }

    if (nextStepType === PropertyClientType.Address) {
      return t("vcc_nextAddress");
    }

    return t("continue_text");
  };

  return (
    <CardContainer>
      <CardContainer.Content>
        <Grid item width="100%">
          {error && <FormErrorBox error={error} />}

          <form>
            {/* DEBIT CARD */}
            {selectedPaymentMethod.payment_type === PaymentType.DebitCard && (
              <DebitCardForm
                paymentMethod={selectedPaymentMethod}
                formState={formState}
                handleInputChange={handleInputChange}
                errors={inputErrors}
                handleEncryptedDetailsChange={setEncryptedCardDetails}
              />
            )}

            {/* ALL PROPS */}
            {currentFormStep < 1 &&
              selectedPaymentMethod.payment_type !== PaymentType.DebitCard &&
              selectedPaymentMethod.properties?.map(
                (property: PaymentMethodProp, index: number) => {
                  if (!property.nestedProps?.length) {
                    return renderPropertyInput(
                      property,
                      property.property_name,
                      index
                    );
                  }
                }
              )}

            {/* NESTED PROPS */}
            {propWithNestedProps && currentFormStep > 0 && renderNestedForm()}

            {/* BUTTON */}
            <Grid item>
              <LoadingButton
                data-test-id="form-button"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                onClick={(event) => handleSubmit(event)}
                disabled={isSubmitDisabled}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<WhiteSecurityIcon />}
              >
                {getButtonTitle()}
              </LoadingButton>
            </Grid>
          </form>
        </Grid>
      </CardContainer.Content>
    </CardContainer>
  );
};

export default PaymentInputForm;
