/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  StipendAcceptRequestBody,
  StipendAcceptResponseResponse,
  StipendIndexResponseResponse,
  StipendReadResponseResponse,
  StipendRejectRequestBody,
  StipendRejectResponseResponse,
} from "./resources-stipends.yml";
import type {
  AcceptStipendByIdParams,
  BadRequestErrorResponseResponse,
  ListAllStipendsParams,
  NotFoundErrorResponseResponse,
  ReadStipendByIdParams,
  RejectStipendByIdParams,
  ServerErrorResponseResponse,
  ValidationErrorResponseResponse,
} from ".";
import { nmibleInstance } from "../mutator/axios-instance";
import type { ErrorType, BodyType } from "../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `stipends.index` permission.

# Extra information
- This endpoint will return a paginated list of stipends.

 * @summary List all stipends.
 */
export const listAllStipends = (
  params?: ListAllStipendsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<StipendIndexResponseResponse>(
    { url: `/stipends`, method: "GET", params, signal },
    options
  );
};

export const getListAllStipendsQueryKey = (params?: ListAllStipendsParams) => {
  return [`/stipends`, ...(params ? [params] : [])] as const;
};

export const getListAllStipendsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllStipends>>,
  TError = ErrorType<
    BadRequestErrorResponseResponse | ServerErrorResponseResponse
  >
>(
  params?: ListAllStipendsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllStipends>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllStipendsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllStipends>>> = ({
    signal,
  }) => listAllStipends(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllStipends>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllStipendsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllStipends>>
>;
export type ListAllStipendsQueryError = ErrorType<
  BadRequestErrorResponseResponse | ServerErrorResponseResponse
>;

export function useListAllStipends<
  TData = Awaited<ReturnType<typeof listAllStipends>>,
  TError = ErrorType<
    BadRequestErrorResponseResponse | ServerErrorResponseResponse
  >
>(
  params: undefined | ListAllStipendsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllStipends>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllStipends>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllStipends<
  TData = Awaited<ReturnType<typeof listAllStipends>>,
  TError = ErrorType<
    BadRequestErrorResponseResponse | ServerErrorResponseResponse
  >
>(
  params?: ListAllStipendsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllStipends>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllStipends>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllStipends<
  TData = Awaited<ReturnType<typeof listAllStipends>>,
  TError = ErrorType<
    BadRequestErrorResponseResponse | ServerErrorResponseResponse
  >
>(
  params?: ListAllStipendsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllStipends>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all stipends.
 */

export function useListAllStipends<
  TData = Awaited<ReturnType<typeof listAllStipends>>,
  TError = ErrorType<
    BadRequestErrorResponseResponse | ServerErrorResponseResponse
  >
>(
  params?: ListAllStipendsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllStipends>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllStipendsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `stipends.read` permission.

# Extra information
- This endpoint will the requested stipend.

 * @summary Read stipend by ID.
 */
export const readStipendById = (
  id: string,
  params?: ReadStipendByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<StipendReadResponseResponse>(
    { url: `/stipends/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadStipendByIdQueryKey = (
  id: string,
  params?: ReadStipendByIdParams
) => {
  return [`/stipends/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadStipendByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readStipendById>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(
  id: string,
  params?: ReadStipendByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readStipendById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadStipendByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readStipendById>>> = ({
    signal,
  }) => readStipendById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readStipendById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadStipendByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readStipendById>>
>;
export type ReadStipendByIdQueryError = ErrorType<
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse
>;

export function useReadStipendById<
  TData = Awaited<ReturnType<typeof readStipendById>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(
  id: string,
  params: undefined | ReadStipendByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readStipendById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readStipendById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadStipendById<
  TData = Awaited<ReturnType<typeof readStipendById>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(
  id: string,
  params?: ReadStipendByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readStipendById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readStipendById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadStipendById<
  TData = Awaited<ReturnType<typeof readStipendById>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(
  id: string,
  params?: ReadStipendByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readStipendById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read stipend by ID.
 */

export function useReadStipendById<
  TData = Awaited<ReturnType<typeof readStipendById>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(
  id: string,
  params?: ReadStipendByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readStipendById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadStipendByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `stipends.accept` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the approved stipend.

 * @summary Accept a stipend by ID.
 */
export const acceptStipendById = (
  id: string,
  stipendAcceptRequestBody: BodyType<StipendAcceptRequestBody>,
  params?: AcceptStipendByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<StipendAcceptResponseResponse>(
    {
      url: `/stipends/${id}/accept`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: stipendAcceptRequestBody,
      params,
    },
    options
  );
};

export const getAcceptStipendByIdMutationOptions = <
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptStipendById>>,
    TError,
    {
      id: string;
      data: BodyType<StipendAcceptRequestBody>;
      params?: AcceptStipendByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptStipendById>>,
  TError,
  {
    id: string;
    data: BodyType<StipendAcceptRequestBody>;
    params?: AcceptStipendByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptStipendById>>,
    {
      id: string;
      data: BodyType<StipendAcceptRequestBody>;
      params?: AcceptStipendByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return acceptStipendById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptStipendByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptStipendById>>
>;
export type AcceptStipendByIdMutationBody = BodyType<StipendAcceptRequestBody>;
export type AcceptStipendByIdMutationError = ErrorType<
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse
>;

/**
 * @summary Accept a stipend by ID.
 */
export const useAcceptStipendById = <
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptStipendById>>,
    TError,
    {
      id: string;
      data: BodyType<StipendAcceptRequestBody>;
      params?: AcceptStipendByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof acceptStipendById>>,
  TError,
  {
    id: string;
    data: BodyType<StipendAcceptRequestBody>;
    params?: AcceptStipendByIdParams;
  },
  TContext
> => {
  const mutationOptions = getAcceptStipendByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `stipends.reject` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the rejected stipend.

 * @summary Reject a stipend by ID.
 */
export const rejectStipendById = (
  id: string,
  stipendRejectRequestBody: BodyType<StipendRejectRequestBody>,
  params?: RejectStipendByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<StipendRejectResponseResponse>(
    {
      url: `/stipends/${id}/reject`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: stipendRejectRequestBody,
      params,
    },
    options
  );
};

export const getRejectStipendByIdMutationOptions = <
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectStipendById>>,
    TError,
    {
      id: string;
      data: BodyType<StipendRejectRequestBody>;
      params?: RejectStipendByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectStipendById>>,
  TError,
  {
    id: string;
    data: BodyType<StipendRejectRequestBody>;
    params?: RejectStipendByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectStipendById>>,
    {
      id: string;
      data: BodyType<StipendRejectRequestBody>;
      params?: RejectStipendByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return rejectStipendById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectStipendByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectStipendById>>
>;
export type RejectStipendByIdMutationBody = BodyType<StipendRejectRequestBody>;
export type RejectStipendByIdMutationError = ErrorType<
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse
>;

/**
 * @summary Reject a stipend by ID.
 */
export const useRejectStipendById = <
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectStipendById>>,
    TError,
    {
      id: string;
      data: BodyType<StipendRejectRequestBody>;
      params?: RejectStipendByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectStipendById>>,
  TError,
  {
    id: string;
    data: BodyType<StipendRejectRequestBody>;
    params?: RejectStipendByIdParams;
  },
  TContext
> => {
  const mutationOptions = getRejectStipendByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
