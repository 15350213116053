import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { usePageTitle } from "../../common/TitleContext";
import { useLocation } from "react-router-dom";
import TaxFormSubmissionSkeleton from "./TaxFormSubmissionSkeleton";
import { useTranslation } from "react-i18next";

function TaxFormSubmissionPage() {
  const { t } = useTranslation();
  usePageTitle(t("tax_form"));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get("url") || "";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframe = document.getElementById(
      "iframe-content"
    ) as HTMLIFrameElement;
    const handleLoad = () => {
      setLoading(false);
    };
    if (iframe) {
      iframe.addEventListener("load", handleLoad);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleLoad);
      }
    };
  }, [url]);

  return (
    <Box position="relative" width="100%" height="calc(100vh - 95px)">
      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={2}
          bgcolor="white"
        >
          <TaxFormSubmissionSkeleton isMobile={isMobile} />
        </Box>
      )}
      <iframe
        id="iframe-content"
        src={url}
        width="100%"
        height="100%"
        style={{
          border: "none",
          zIndex: 1,
        }}
        onLoad={() => setLoading(false)}
      />
    </Box>
  );
}

export default TaxFormSubmissionPage;
