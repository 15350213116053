/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ReceiptCreateRequestBody,
  ReceiptCreateResponseResponse,
  ReceiptDeleteResponseResponse,
  ReceiptIndexResponseResponse,
  ReceiptReadResponseResponse,
  ReceiptRestoreResponseResponse,
  ReceiptUpdateRequestBody,
  ReceiptUpdateResponseResponse,
} from "../../model/resources-receipts.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewReceiptParams,
  ListAllReceiptsParams,
  NotFoundErrorResponseResponse,
  ReadReceiptByIdParams,
  RestoreReceiptByIdParams,
  ServerErrorResponseResponse,
  UpdateReceiptByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.index` permission.

# Extra information
- This endpoint will return a paginated list of receipts.

 * @summary List all receipts.
 */
export const listAllReceipts = (
  params?: ListAllReceiptsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ReceiptIndexResponseResponse>(
    { url: `/receipts`, method: "GET", params, signal },
    options
  );
};

export const getListAllReceiptsQueryKey = (params?: ListAllReceiptsParams) => {
  return [`/receipts`, ...(params ? [params] : [])] as const;
};

export const getListAllReceiptsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllReceipts>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllReceiptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllReceipts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllReceiptsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllReceipts>>> = ({
    signal,
  }) => listAllReceipts(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllReceipts>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllReceiptsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllReceipts>>
>;
export type ListAllReceiptsQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllReceipts<
  TData = Awaited<ReturnType<typeof listAllReceipts>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllReceiptsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllReceipts>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllReceipts>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllReceipts<
  TData = Awaited<ReturnType<typeof listAllReceipts>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllReceiptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllReceipts>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllReceipts>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllReceipts<
  TData = Awaited<ReturnType<typeof listAllReceipts>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllReceiptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllReceipts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all receipts.
 */

export function useListAllReceipts<
  TData = Awaited<ReturnType<typeof listAllReceipts>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllReceiptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllReceipts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllReceiptsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.create` permission.

# Extra information
- This endpoint will return the newly created receipt.

 * @summary Create a new receipt.
 */
export const createNewReceipt = (
  receiptCreateRequestBody: ReceiptCreateRequestBody,
  params?: CreateNewReceiptParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ReceiptCreateResponseResponse>(
    {
      url: `/receipts`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: receiptCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewReceiptMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewReceipt>>,
    TError,
    { data: ReceiptCreateRequestBody; params?: CreateNewReceiptParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewReceipt>>,
  TError,
  { data: ReceiptCreateRequestBody; params?: CreateNewReceiptParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewReceipt>>,
    { data: ReceiptCreateRequestBody; params?: CreateNewReceiptParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewReceipt(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewReceiptMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewReceipt>>
>;
export type CreateNewReceiptMutationBody = ReceiptCreateRequestBody;
export type CreateNewReceiptMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new receipt.
 */
export const useCreateNewReceipt = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewReceipt>>,
    TError,
    { data: ReceiptCreateRequestBody; params?: CreateNewReceiptParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewReceipt>>,
  TError,
  { data: ReceiptCreateRequestBody; params?: CreateNewReceiptParams },
  TContext
> => {
  const mutationOptions = getCreateNewReceiptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.read` permission.

# Extra information
- This endpoint will the requested receipt.

 * @summary Read receipt by ID.
 */
export const readReceiptById = (
  id: string,
  params?: ReadReceiptByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ReceiptReadResponseResponse>(
    { url: `/receipts/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadReceiptByIdQueryKey = (
  id: string,
  params?: ReadReceiptByIdParams
) => {
  return [`/receipts/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadReceiptByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readReceiptById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadReceiptByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readReceiptById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadReceiptByIdQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readReceiptById>>> = ({
    signal,
  }) => readReceiptById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readReceiptById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadReceiptByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readReceiptById>>
>;
export type ReadReceiptByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadReceiptById<
  TData = Awaited<ReturnType<typeof readReceiptById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadReceiptByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readReceiptById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readReceiptById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadReceiptById<
  TData = Awaited<ReturnType<typeof readReceiptById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadReceiptByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readReceiptById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readReceiptById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadReceiptById<
  TData = Awaited<ReturnType<typeof readReceiptById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadReceiptByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readReceiptById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read receipt by ID.
 */

export function useReadReceiptById<
  TData = Awaited<ReturnType<typeof readReceiptById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadReceiptByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readReceiptById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadReceiptByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated receipt.

 * @summary Update receipt by ID.
 */
export const updateReceiptById = (
  id: string,
  receiptUpdateRequestBody: ReceiptUpdateRequestBody,
  params?: UpdateReceiptByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ReceiptUpdateResponseResponse>(
    {
      url: `/receipts/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: receiptUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateReceiptByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceiptById>>,
    TError,
    {
      id: string;
      data: ReceiptUpdateRequestBody;
      params?: UpdateReceiptByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReceiptById>>,
  TError,
  {
    id: string;
    data: ReceiptUpdateRequestBody;
    params?: UpdateReceiptByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReceiptById>>,
    {
      id: string;
      data: ReceiptUpdateRequestBody;
      params?: UpdateReceiptByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateReceiptById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReceiptByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReceiptById>>
>;
export type UpdateReceiptByIdMutationBody = ReceiptUpdateRequestBody;
export type UpdateReceiptByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update receipt by ID.
 */
export const useUpdateReceiptById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceiptById>>,
    TError,
    {
      id: string;
      data: ReceiptUpdateRequestBody;
      params?: UpdateReceiptByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReceiptById>>,
  TError,
  {
    id: string;
    data: ReceiptUpdateRequestBody;
    params?: UpdateReceiptByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateReceiptByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.delete` permission.

# Extra information
- This endpoint will return an empty response body.

 * @summary Delete receipt by ID.
 */
export const deleteReceiptById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ReceiptDeleteResponseResponse>(
    { url: `/receipts/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteReceiptByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceiptById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceiptById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceiptById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteReceiptById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceiptByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceiptById>>
>;

export type DeleteReceiptByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete receipt by ID.
 */
export const useDeleteReceiptById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceiptById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceiptById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteReceiptByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `receipts.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored document.

 * @summary Restore receipt by ID.
 */
export const restoreReceiptById = (
  id: string,
  params?: RestoreReceiptByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ReceiptRestoreResponseResponse>(
    { url: `/receipts/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreReceiptByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreReceiptById>>,
    TError,
    { id: string; params?: RestoreReceiptByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreReceiptById>>,
  TError,
  { id: string; params?: RestoreReceiptByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreReceiptById>>,
    { id: string; params?: RestoreReceiptByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreReceiptById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreReceiptByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreReceiptById>>
>;

export type RestoreReceiptByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore receipt by ID.
 */
export const useRestoreReceiptById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreReceiptById>>,
    TError,
    { id: string; params?: RestoreReceiptByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreReceiptById>>,
  TError,
  { id: string; params?: RestoreReceiptByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreReceiptByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
