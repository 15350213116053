import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  hasMoreData: boolean;
  loadingMoreData: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function LoadMoreButton({
  hasMoreData,
  loadingMoreData,
  onClick,
}: Props) {
  const { t } = useTranslation();

  if (!hasMoreData) return null;

  function button() {
    return (
      <LoadingButton
        data-test-id="load-more-button"
        size="medium"
        variant="outlined"
        color="secondary"
        sx={{ display: "flex", margin: "auto" }}
        onClick={onClick}
        loading={loadingMoreData}
        loadingPosition="start"
      >
        {t("loadMore")}
      </LoadingButton>
    );
  }

  function loadingIndicator() {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      {loadingMoreData ? loadingIndicator() : button()}
    </Box>
  );
}
