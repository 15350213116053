/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ParticipantVisitCreateRequestBody,
  ParticipantVisitCreateResponseResponse,
  ParticipantVisitDeleteResponseResponse,
  ParticipantVisitIndexResponseResponse,
  ParticipantVisitReadResponseResponse,
  ParticipantVisitRestoreResponseResponse,
  ParticipantVisitUpdateRequestBody,
  ParticipantVisitUpdateResponseResponse,
} from "../../model/resources-participant-visits.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewParticipantVisitParams,
  ListAllParticipantVisitsParams,
  NotFoundErrorResponseResponse,
  ReadParticipantVisitByIdParams,
  RestoreParticipantVisitByIdParams,
  ServerErrorResponseResponse,
  UpdateParticipantVisitByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.index` permission.

# Extra information
- This endpoint will return a paginated list of participant-visits.

 * @summary List all participant-visits.
 */
export const listAllParticipantVisits = (
  params?: ListAllParticipantVisitsParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantVisitIndexResponseResponse>(
    { url: `/participant-visits`, method: "GET", params, signal },
    options
  );
};

export const getListAllParticipantVisitsQueryKey = (
  params?: ListAllParticipantVisitsParams
) => {
  return [`/participant-visits`, ...(params ? [params] : [])] as const;
};

export const getListAllParticipantVisitsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllParticipantVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllParticipantVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllParticipantVisits>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllParticipantVisitsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAllParticipantVisits>>
  > = ({ signal }) => listAllParticipantVisits(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllParticipantVisits>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllParticipantVisitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllParticipantVisits>>
>;
export type ListAllParticipantVisitsQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllParticipantVisits<
  TData = Awaited<ReturnType<typeof listAllParticipantVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllParticipantVisitsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllParticipantVisits>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllParticipantVisits>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllParticipantVisits<
  TData = Awaited<ReturnType<typeof listAllParticipantVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllParticipantVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllParticipantVisits>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllParticipantVisits>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllParticipantVisits<
  TData = Awaited<ReturnType<typeof listAllParticipantVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllParticipantVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllParticipantVisits>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all participant-visits.
 */

export function useListAllParticipantVisits<
  TData = Awaited<ReturnType<typeof listAllParticipantVisits>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllParticipantVisitsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllParticipantVisits>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllParticipantVisitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.create` permission.

# Extra information
- This endpoint will return the newly created participant visit.

 * @summary Create a new participant visit.
 */
export const createNewParticipantVisit = (
  participantVisitCreateRequestBody: ParticipantVisitCreateRequestBody,
  params?: CreateNewParticipantVisitParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantVisitCreateResponseResponse>(
    {
      url: `/participant-visits`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantVisitCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewParticipantVisitMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewParticipantVisit>>,
    TError,
    {
      data: ParticipantVisitCreateRequestBody;
      params?: CreateNewParticipantVisitParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewParticipantVisit>>,
  TError,
  {
    data: ParticipantVisitCreateRequestBody;
    params?: CreateNewParticipantVisitParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewParticipantVisit>>,
    {
      data: ParticipantVisitCreateRequestBody;
      params?: CreateNewParticipantVisitParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewParticipantVisit(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewParticipantVisitMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewParticipantVisit>>
>;
export type CreateNewParticipantVisitMutationBody =
  ParticipantVisitCreateRequestBody;
export type CreateNewParticipantVisitMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new participant visit.
 */
export const useCreateNewParticipantVisit = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewParticipantVisit>>,
    TError,
    {
      data: ParticipantVisitCreateRequestBody;
      params?: CreateNewParticipantVisitParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewParticipantVisit>>,
  TError,
  {
    data: ParticipantVisitCreateRequestBody;
    params?: CreateNewParticipantVisitParams;
  },
  TContext
> => {
  const mutationOptions = getCreateNewParticipantVisitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.read` permission.

# Extra information
- This endpoint will the requested participant visit.

 * @summary Read participant-visit by ID.
 */
export const readParticipantVisitById = (
  id: string,
  params?: ReadParticipantVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ParticipantVisitReadResponseResponse>(
    { url: `/participant-visits/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadParticipantVisitByIdQueryKey = (
  id: string,
  params?: ReadParticipantVisitByIdParams
) => {
  return [`/participant-visits/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadParticipantVisitByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readParticipantVisitById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadParticipantVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readParticipantVisitById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadParticipantVisitByIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof readParticipantVisitById>>
  > = ({ signal }) =>
    readParticipantVisitById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readParticipantVisitById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadParticipantVisitByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readParticipantVisitById>>
>;
export type ReadParticipantVisitByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadParticipantVisitById<
  TData = Awaited<ReturnType<typeof readParticipantVisitById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadParticipantVisitByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readParticipantVisitById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readParticipantVisitById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadParticipantVisitById<
  TData = Awaited<ReturnType<typeof readParticipantVisitById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadParticipantVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readParticipantVisitById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readParticipantVisitById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadParticipantVisitById<
  TData = Awaited<ReturnType<typeof readParticipantVisitById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadParticipantVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readParticipantVisitById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read participant-visit by ID.
 */

export function useReadParticipantVisitById<
  TData = Awaited<ReturnType<typeof readParticipantVisitById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadParticipantVisitByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readParticipantVisitById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadParticipantVisitByIdQueryOptions(
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated participant visit.

 * @summary Update participant-visit by ID.
 */
export const updateParticipantVisitById = (
  id: string,
  participantVisitUpdateRequestBody: ParticipantVisitUpdateRequestBody,
  params?: UpdateParticipantVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ParticipantVisitUpdateResponseResponse>(
    {
      url: `/participant-visits/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: participantVisitUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateParticipantVisitByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateParticipantVisitById>>,
    TError,
    {
      id: string;
      data: ParticipantVisitUpdateRequestBody;
      params?: UpdateParticipantVisitByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateParticipantVisitById>>,
  TError,
  {
    id: string;
    data: ParticipantVisitUpdateRequestBody;
    params?: UpdateParticipantVisitByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateParticipantVisitById>>,
    {
      id: string;
      data: ParticipantVisitUpdateRequestBody;
      params?: UpdateParticipantVisitByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateParticipantVisitById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateParticipantVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateParticipantVisitById>>
>;
export type UpdateParticipantVisitByIdMutationBody =
  ParticipantVisitUpdateRequestBody;
export type UpdateParticipantVisitByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update participant-visit by ID.
 */
export const useUpdateParticipantVisitById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateParticipantVisitById>>,
    TError,
    {
      id: string;
      data: ParticipantVisitUpdateRequestBody;
      params?: UpdateParticipantVisitByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateParticipantVisitById>>,
  TError,
  {
    id: string;
    data: ParticipantVisitUpdateRequestBody;
    params?: UpdateParticipantVisitByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateParticipantVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a participant-visit by ID.
 */
export const deleteParticipantVisitById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ParticipantVisitDeleteResponseResponse>(
    { url: `/participant-visits/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteParticipantVisitByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteParticipantVisitById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteParticipantVisitById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteParticipantVisitById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteParticipantVisitById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteParticipantVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteParticipantVisitById>>
>;

export type DeleteParticipantVisitByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a participant-visit by ID.
 */
export const useDeleteParticipantVisitById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteParticipantVisitById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteParticipantVisitById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteParticipantVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `participant-visits.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored participant visit.

 * @summary Restore a participant-visit by ID.
 */
export const restoreParticipantVisitById = (
  id: string,
  params?: RestoreParticipantVisitByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ParticipantVisitRestoreResponseResponse>(
    { url: `/participant-visits/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreParticipantVisitByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreParticipantVisitById>>,
    TError,
    { id: string; params?: RestoreParticipantVisitByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreParticipantVisitById>>,
  TError,
  { id: string; params?: RestoreParticipantVisitByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreParticipantVisitById>>,
    { id: string; params?: RestoreParticipantVisitByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreParticipantVisitById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreParticipantVisitByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreParticipantVisitById>>
>;

export type RestoreParticipantVisitByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a participant-visit by ID.
 */
export const useRestoreParticipantVisitById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreParticipantVisitById>>,
    TError,
    { id: string; params?: RestoreParticipantVisitByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreParticipantVisitById>>,
  TError,
  { id: string; params?: RestoreParticipantVisitByIdParams },
  TContext
> => {
  const mutationOptions =
    getRestoreParticipantVisitByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
