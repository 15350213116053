import { Box, Skeleton } from "@mui/material";
import React from "react";

const TaxFormSubmissionSkeleton: React.FC<{ isMobile: boolean }> = ({
  isMobile,
}) => {
  if (isMobile)
    return (
      <Box maxWidth="980px" my="20px" p="25px 15px" mx="auto">
        <Box mb={2}>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} sx={{ mt: 1 }} />
          <Skeleton width="60%" height={20} sx={{ mt: 1 }} />
        </Box>
        {[...Array(2)].map((_, index) => (
          <Box
            key={index}
            sx={{ border: "1px solid #ccc", borderRadius: "4px", p: 2, mb: 2 }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Skeleton variant="rectangular" width={40} height={40} />
              <Skeleton width="80%" height={20} sx={{ mt: 2 }} />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={40}
                sx={{ mt: 2 }}
              />
              <Skeleton width="60%" height={20} sx={{ mt: 2 }} />
            </Box>
          </Box>
        ))}
      </Box>
    );

  return (
    <Box maxWidth={"980px"} m={"20px auto"} p={"30px 15px"}>
      <Skeleton width="80%" />
      <Skeleton width="80%" />
      <Skeleton width="60%" />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Box sx={{ width: "30%", mx: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="50%" height={30} sx={{ mt: 2 }} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ mt: 4 }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
          <Skeleton width={40} height={40} />
        </Box>
        <Box sx={{ width: "30%", mx: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="50%" height={30} sx={{ mt: 2 }} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ mt: 4 }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
          <Skeleton width={40} height={40} />
        </Box>
        <Box sx={{ width: "30%", mx: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          <Skeleton width="50%" height={30} sx={{ mt: 2 }} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ mt: 4 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TaxFormSubmissionSkeleton;
