import { Box, List, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StipendJsonApiBlockWithId } from "../api/model/resources-stipends.yml";
import { useAcceptStipendById } from "../api/model/stipends";
import ListItemCompound from "../components/ExpenseListItem2";
import { ExpenseRowData, formatISODate } from "../types/common";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "../common/hooks";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/slices/toastSlice";

interface PendingStipendsListProps {
  stipends: StipendJsonApiBlockWithId[];
  completedTaxForm: boolean;
}

const PendingStipendsList: React.FC<PendingStipendsListProps> = ({
  stipends,
  completedTaxForm,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStipendId, setCurrentStipendId] = useState<string | null>(null);
  const { handleServerError } = useErrorHandler();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: sendAcceptStipendRequest, isPending: accepting } =
    useAcceptStipendById({
      mutation: {
        onError: (error, variables, context) => {
          handleServerError(error);
        },
        onSuccess: (data, variables, context) => {
          queryClient.removeQueries({
            queryKey: ["/stipends"],
          });
          const id = data.data?.id;
          queryClient.removeQueries({
            queryKey: [`/stipends/${id}`],
          });
          dispatch(showToast(t("claim_stipend_approvedToastMessage")));
        },
        onSettled: (data, error, variables, context) => {
          setCurrentStipendId(null);
        },
      },
    });

  const handleAccept = async (id: string) => {
    setCurrentStipendId(id);

    sendAcceptStipendRequest({
      id: id,
      data: {
        data: {
          type: "stipends",
          id: id,
        },
      },
    });
  };

  if (stipends.length === 0) {
    return null;
  }

  const sectionTitle = (title: string) => {
    return (
      <Typography
        sx={{
          mt: 2,
          mb: 1,
        }}
        variant="subtitle2"
        color="text.secondary"
        fontWeight="600"
      >
        {title}
      </Typography>
    );
  };

  return (
    <>
      {sectionTitle(t("claim_stipend_sectionTitle"))}

      <List>
        {stipends.map((stipend) => {
          const creationDate = formatISODate(
            stipend.attributes.createdAt!,
            "date"
          );

          const listItemData: ExpenseRowData = {
            id: stipend.id!,
            createdAt: creationDate,
            type: "Stipend",
            amount: stipend.attributes.amount,
            status: stipend.attributes.status,
          };
          const isPending = listItemData.status.toLowerCase() === "pending";

          return (
            <ListItemCompound
              key={stipend.id!}
              onClick={() => {
                navigate(`/stipend/${stipend.id}`);
              }}
            >
              <ListItemCompound.Content>
                <ListItemCompound.Icon type={listItemData.type} />

                <ListItemCompound.Text>
                  <ListItemCompound.PrimaryText>
                    {`Stipend - ${listItemData.id}`}
                  </ListItemCompound.PrimaryText>
                  <ListItemCompound.SecondaryText>
                    {listItemData.createdAt}
                  </ListItemCompound.SecondaryText>
                  <ListItemCompound.Badge status={t(listItemData.status)} />
                </ListItemCompound.Text>
              </ListItemCompound.Content>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <ListItemCompound.TrailingContent>
                  ${listItemData.amount}
                </ListItemCompound.TrailingContent>

                {isPending && completedTaxForm && (
                  <ListItemCompound.Action
                    action={() => handleAccept(listItemData.id)}
                    executingAction={
                      accepting && currentStipendId === listItemData.id
                    }
                  >
                    {t("accept")}
                  </ListItemCompound.Action>
                )}
              </Box>
            </ListItemCompound>
          );
        })}
      </List>
    </>
  );
};

export default PendingStipendsList;
