import { ChangeEvent, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ForgetPinButton from "../common/ForgetPinButton";
import { getErrorMsg } from "../common/helpers";
import StorageManager from "../services/storage";
import CardContainer from "./CardContainer";
import { useUpdateAParticipantPin } from "../api/client/participant/participant";
import { ParticipantUpdatePinRequestBody } from "../api/model/resources-participant.yml/participantUpdatePinRequestBody";
import { AxiosError } from "axios";

interface IFormState {
  currentPin: IInputValue;
  newPin: IInputValue;
  newPinConfirmation: IInputValue;
}

//TOOD: REMOVE
interface IInputValue {
  value: string;
  isDirty: boolean;
}
//TOOD: REMOVE
const defaultInputValue = { value: "", isDirty: false };

interface Props {
  isDialogMode?: boolean;
  onSuccess?: () => void;
}

const ChangePINPage: React.FC<Props> = ({
  isDialogMode = false,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [serverError, setServerError] = useState("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showPIN, setShowPIN] = useState<boolean>(false);

  const [formState, setFormState] = useState<IFormState>({
    currentPin: defaultInputValue,
    newPin: defaultInputValue,
    newPinConfirmation: defaultInputValue,
  });

  const { mutate, isPending, isError, isSuccess } = useUpdateAParticipantPin({
    mutation: {
      onError: (error, variables, context) => {
        const errorMsg = getErrorMsg(t, error as AxiosError);
        setServerError(errorMsg);
      },
      onSuccess: (data, variables, context) => {
        const userData = data.data?.attributes;
        if (!userData) return;
        StorageManager.updateRequiresPinChange(userData.requiresPinChange);

        if (isDialogMode && onSuccess) onSuccess();
      },
      onSettled: (data, error, variables, context) => {
        setFormState({
          currentPin: defaultInputValue,
          newPin: defaultInputValue,
          newPinConfirmation: defaultInputValue,
        });
      },
    },
  });

  useEffect(() => {
    const validationResult = validateForm();

    setIsFormValid(validationResult);
  }, [formState]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;

    setFormState((prevState) => ({
      ...prevState,
      [key]: { value, isDirty: true },
    }));
  };

  const validateForm = () => {
    setErrors((prevState) => ({}));

    let isValid = true;

    if (
      !formState.currentPin.value ||
      !formState.newPin.value ||
      !formState.newPinConfirmation.value
    ) {
      isValid = false;
    }

    if (!formState.currentPin.value && formState.currentPin.isDirty) {
      setErrors((prevState) => ({
        ...prevState,
        currentPin: t("enterPin_title"),
      }));

      isValid = false;
    }

    if (formState.currentPin.value && formState.currentPin.value.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        currentPin: t("choosePin_subtitle"),
      }));

      isValid = false;
    }

    if (formState.newPin.value && formState.newPinConfirmation.value) {
      if (formState.newPin.value !== formState.newPinConfirmation.value) {
        setErrors((prevState) => ({
          ...prevState,
          newPin: t("error_notMatchedPin"),
          newPinConfirmation: t("error_notMatchedPin"),
        }));

        isValid = false;
      }
    }

    if (formState.newPin.value && formState.newPin.value.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        newPin: t("choosePin_subtitle"),
      }));

      isValid = false;
    }

    return isValid;
  };

  const handleSubmitClick = (event: React.FormEvent) => {
    event.preventDefault();

    const data: ParticipantUpdatePinRequestBody = {
      currentPin: formState.currentPin.value,
      newPin: formState.newPin.value,
      newPin_confirmation: formState.newPinConfirmation.value,
    };
    mutate({ data });
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  };

  return (
    <Grid>
      <CardContainer showBorder={!isDialogMode}>
        <CardContainer.Header>
          <CardContainer.HeaderContent>
            <CardContainer.HeaderTextItem>
              {t("settings_changePin")}
            </CardContainer.HeaderTextItem>
            <CardContainer.HeaderTextItem>
              {t("change_pin_subtitle")}
            </CardContainer.HeaderTextItem>
          </CardContainer.HeaderContent>
        </CardContainer.Header>
        <CardContainer.Content>
          {isDialogMode && (
            <Alert data-test-id="changepin-msg" severity="warning">
              {t("changePin_msg")}
            </Alert>
          )}
          {isError && serverError && (
            <Alert data-test-id="error-msg" severity="error">
              {serverError}
            </Alert>
          )}
          {isSuccess && (
            <Alert data-test-id="success-msg" severity="success">
              <div data-test-id="success-msg-p1">
                {t("changePin_pinChangedConfirmationMessage_p1")}
              </div>
              <div data-test-id="success-msg-p2">
                {t("changePin_pinChangedConfirmationMessage_p2")}
              </div>
            </Alert>
          )}
          <form>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-current-pin">
                {t("current_pin")}
              </InputLabel>

              <OutlinedInput
                name="currentPin"
                error={!!errors["currentPin"]}
                onChange={handleInputChange}
                onKeyPress={(event) => {
                  if (/[^0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={formState.currentPin.value}
                data-test-id="current-pin-textfield"
                type={showPIN ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPIN}
                      edge="end"
                    >
                      {showPIN ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("current_pin")}
              />

              {!!errors["currentPin"] && (
                <FormHelperText error id="accountId-error">
                  {errors["currentPin"]}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-new-pin">
                {t("changePin_chooseNewPin")}
              </InputLabel>

              <OutlinedInput
                name="newPin"
                error={!!errors["newPin"]}
                onChange={handleInputChange}
                onKeyPress={(event) => {
                  if (/[^0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={formState.newPin.value}
                data-test-id="new-pin-textfield"
                type={showPIN ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPIN}
                      edge="end"
                    >
                      {showPIN ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("changePin_chooseNewPin")}
              />

              {!!errors["newPin"] && (
                <FormHelperText error id="accountId-error">
                  {errors["newPin"]}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-pin">
                {t("changePin_confirmNewPinSubtitle")}
              </InputLabel>

              <OutlinedInput
                name="newPinConfirmation"
                error={!!errors["newPinConfirmation"]}
                onChange={handleInputChange}
                onKeyPress={(event) => {
                  if (/[^0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={formState.newPinConfirmation.value}
                data-test-id="confirm-pin-textfield"
                type={showPIN ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPIN}
                      edge="end"
                    >
                      {showPIN ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("changePin_confirmNewPinSubtitle")}
              />

              {!!errors["newPinConfirmation"] && (
                <FormHelperText error id="accountId-error">
                  {errors["newPinConfirmation"]}
                </FormHelperText>
              )}
            </FormControl>

            <LoadingButton
              data-test-id="submit-button"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 3, mb: isDialogMode ? 1 : 2 }}
              loading={isPending}
              onClick={handleSubmitClick}
              disabled={!isFormValid}
            >
              {t("continue_text")}
            </LoadingButton>

            {!isDialogMode && <ForgetPinButton />}
          </form>
        </CardContainer.Content>
      </CardContainer>
    </Grid>
  );
};

export default ChangePINPage;
