import { AxiosError } from "axios";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorFallback from "./ErrorFallback";

interface CustomErrorBoundaryProps {
  children: React.ReactNode;
}

const CustomErrorBoundary: React.FC<CustomErrorBoundaryProps> = ({
  children,
}) => {
  // const navigate = useNavigate();
  const pathname = useLocation().pathname;

  return (
    <ErrorBoundary
      key={pathname}
      FallbackComponent={ErrorFallback}
      onError={(error) => console.log("Received error", error)}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
