import { Navigate, Route, Routes } from "react-router-dom";

import { configService } from "../services/configService";

import PaymentInputForm from "../containers/PaymentInputForm";

import ChangePINPage from "../pages/ChangePinPage";
import ClaimVisitsPage from "../pages/ClaimVisitsPage";
import EnableNotificationsPage from "../pages/EnableNotificationsPage";
import LoginPage from "../pages/LoginPage";
import MileageDistancePage from "../pages/MileageDistancePage";
import MileageSummaryPage from "../pages/MileageSummaryPage";
import MileageThresholdPage from "../pages/MileageThresholdPage";
import NotFound from "../pages/NotFound";
import PaymentDetailsPage from "../pages/PaymentDetailsPage";
import PaymentMethods from "../pages/PaymentMethodsPage";
import StipendSummaryPage from "../pages/PaymentSummaryPage";
import PrivateRoutes from "../pages/PrivateRoutes";
import ReceiptAmountPage from "../pages/ReceiptAmountPage";
import ReceiptCategoriesPage from "../pages/ReceiptCategoriesPage";
import ReceiptImagePage from "../pages/ReceiptImagePage";
import ReceiptSummaryPage from "../pages/ReceiptSummaryPage";
import RejectStipendPayment from "../pages/RejectStipendPayment";
import SettingsPage from "../pages/SettingsPage";
import Support from "../pages/SupportPage";
import TermsAndConditions from "../pages/TermsAndConditionsPage";
import VccCardCreated from "../pages/VccCardCreatedPage";
import VccCardPreview from "../pages/VccCardPreview";
import VccCardReview from "../pages/VccCardReviewPage";
import VccCardSetup from "../pages/VccCardSetup";
import VccVisualise from "../pages/VccVisualisePage";
// import VehicleQuestionsPage from "../pages/VehicleQuestionsPage";
import PublicRoutes from "../pages/PublicRoutes";
import CardOnboardingPage from "../pages/card/CardOnboardingPage";
import NewCardSuccessPage from "../pages/card/NewCardSuccessPage";
import CardForm from "../pages/card/CardForm";
import NewPaymentsPage from "../pages/NewPaymentsPage";
import TrialDetailsPage from "../pages/TrialDetailsPage";
import CardDetailsPage from "../pages/card/CardDetailsPage";
import CardFAQDetailsPage from "../pages/card/CardFAQDetailsPage";
import CardFAQsPage from "../pages/card/CardFAQsPage";
import VisitsPage from "../pages/VisitsPage";
import ExpenseDetails from "../pages/ExpenseDetails";
import TaxPage from "../pages/Tax/TaxPage";
import TaxOnboardingPage from "../pages/Tax/TaxOnboardingPage";
import CreateTaxFormPage from "../pages/Tax/CreateTaxFormPage";
import TaxFormSubmissionPage from "../pages/Tax/TaxFormSubmissionPage";
import StipendDetails from "../pages/StipendDetails";

const AppRoutes = () => {
  const showSupportRoute = configService.config.showSupport;

  if (window.top && window.self !== window.top) {
    // The page is inside an iframe, break out
    window.top.location = window.self.location;
    return;
  }

  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/new-card-onboarding" element={<CardOnboardingPage />} />
        <Route path="/new-card-success" element={<NewCardSuccessPage />} />

        <Route path="/" element={<Navigate replace to="/payments" />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/visits" element={<VisitsPage />} />
        {/* <Route path="/visits" element={<ComingSoonPage />} /> */}
        <Route path="/trial" element={<TrialDetailsPage />} />
        {/* <Route path="/trial" element={<ComingSoonPage />} /> */}
        <Route path="/settings/change-pin" element={<ChangePINPage />} />

        <Route path="/expense/:id" element={<ExpenseDetails />} />
        <Route path="/stipend/:id" element={<StipendDetails />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/vcc-card" element={<VccVisualise />} />
        <Route path="/card/details" element={<CardDetailsPage />} />
        <Route path="/card/faq" element={<CardFAQsPage />} />
        <Route path="/card/faq/:id" element={<CardFAQDetailsPage />} />
        {/* <Route
          path="/enable-notifications"
          element={<EnableNotificationsPage />}
        /> */}
        {/* <Route path="/payments" element={<PaymentsPage />} /> */}
        <Route path="/payments" element={<NewPaymentsPage />} />

        {/* SUBMIT PAGES START*/}
        <Route path="/receipt/submit/upload" element={<ReceiptImagePage />} />

        <Route
          path="/payment/submit/:id/payment-methods"
          element={<PaymentMethods />}
        />
        <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex"
          element={<PaymentInputForm />}
        />

        <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex/preview"
          element={<VccCardPreview />}
        />
        <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex/setup"
          element={<VccCardSetup />}
        />
        <Route
          path="/payment-methods/VIRTUAL_CARD/review"
          element={<VccCardReview />}
        />
        <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex/created"
          element={<VccCardCreated />}
        />

        <Route path="/payment-methods/VIRTUAL_CARD" element={<CardForm />} />

        <Route path="/payment/submit/:id/visit" element={<ClaimVisitsPage />} />
        <Route
          path="/payment/submit/:id/summary"
          element={<StipendSummaryPage />}
        />

        <Route
          path="/payment/submit/receipt/categories"
          element={<ReceiptCategoriesPage />}
        />
        <Route
          path="/payment/submit/receipt/summary"
          element={<ReceiptSummaryPage />}
        />
        <Route
          path="/payment/submit/receipt/amount/:category"
          element={<ReceiptAmountPage />}
        />

        <Route
          path="/payment/submit/mileage/distance"
          element={<MileageDistancePage />}
        />
        {/* <Route
          path="/payment/submit/mileage/vehicle"
          element={<VehicleQuestionsPage />}
        /> */}
        <Route
          path="/payment/submit/mileage/threshold"
          element={<MileageThresholdPage />}
        />
        <Route
          path="/payment/submit/mileage/summary"
          element={<MileageSummaryPage />}
        />

        {/* SUBMIT PAGES END*/}
        <Route path="/payment/:id/details" element={<PaymentDetailsPage />} />
        <Route path="/payment/:id/decline" element={<RejectStipendPayment />} />

        <Route path="/tax">
          <Route index element={<TaxPage />} />
          <Route path="success" element={<Navigate replace to="/tax" />} />
          <Route path="cancelled" element={<Navigate replace to="/tax" />} />
          <Route path="onboarding" element={<TaxOnboardingPage />} />
          <Route path="submit" element={<CreateTaxFormPage />} />
          <Route path="form" element={<TaxFormSubmissionPage />} />
        </Route>

        <Route path="/not-found" element={<NotFound />} />
        {showSupportRoute && <Route path="/support" element={<Support />} />}
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
