import React, { ReactNode, createContext, useContext } from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  Skeleton,
  Stack,
} from "@mui/material";

// Create a Context for the loading state
const LoadingContext = createContext(false);

// Main CardContainer Component
function CardContainer({
  showBorder = false,
  isLoading = false,
  children,
}: {
  showBorder?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}) {
  return (
    <LoadingContext.Provider value={isLoading}>
      <Paper
        elevation={0}
        square={false}
        variant="outlined"
        sx={{
          mt: 2,
          mb: 2,
          borderWidth: showBorder ? "1px" : 0,
        }}
      >
        {children}
      </Paper>
    </LoadingContext.Provider>
  );
}

// Header Component
const Header = ({ children }: { children: ReactNode }) => (
  <>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
        {children}
      </Box>
    </Box>
    <Divider />
  </>
);

// HeaderContent Component for Left Aligned Content
function HeaderContent({ children }: { children: ReactNode }) {
  return (
    <Stack spacing={0.5} sx={{ display: "flex", alignItems: "flex-start" }}>
      {children}
    </Stack>
  );
}

// HeaderTextItem Component for Title and Subtitle
function HeaderTextItem({ children }: { children: ReactNode }) {
  const isLoading = useContext(LoadingContext);
  return (
    <Typography
      component="div"
      sx={{
        "&:first-of-type": {
          fontWeight: "bold",
          fontSize: "1.25rem",
          lineHeight: 1.4,
        },
        "&:not(:first-of-type)": {
          fontWeight: "normal",
          fontSize: "1rem",
          color: "gray",
          lineHeight: 1.3,
        },
      }}
    >
      {isLoading ? <Skeleton width={150} /> : children}
    </Typography>
  );
}

// Icon Component
function Icon({ children }: { children: ReactNode }) {
  const isLoading = useContext(LoadingContext);
  return (
    <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
      {isLoading ? (
        <Skeleton variant="circular" width={40} height={40} />
      ) : (
        children
      )}
    </Box>
  );
}

// TopRightContent Component
function TopRightContent({ children }: { children: ReactNode }) {
  const isLoading = useContext(LoadingContext);
  return (
    <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={80} height={36} />
      ) : (
        children
      )}
    </Box>
  );
}

// Content Component
function Content({ children }: { children: ReactNode }) {
  return <Box sx={{ p: 2 }}>{children}</Box>;
}

// Assign Subcomponents
CardContainer.Header = Header;
CardContainer.HeaderContent = HeaderContent;
CardContainer.HeaderTextItem = HeaderTextItem;
CardContainer.Icon = Icon;
CardContainer.TopRightContent = TopRightContent;
CardContainer.Content = Content;

export default CardContainer;
