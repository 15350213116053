import axios, { AxiosError } from "axios";
import { TFunction } from "i18next";
import { configService } from "../../../services/configService";
import { NotAuthonticatedError } from "../types/NotAuthonticatedError";
import { UnauthorizedError } from "../types/UnauthorizedError";
import { NoConnectionError } from "../types/NoConnectionError";
import { GeneralError } from "../types/GeneralError";
import { ServerError } from "../types/ServerError";
import {
  BadRequestErrorResponseResponse,
  NotFoundErrorResponseResponse,
  ServerErrorResponseResponse,
  ValidationErrorResponseResponse,
} from "../../../api/model";

// interface TermsErrorResponse {
//   detail?: {
//     first_time_user?: boolean;
//   };
// }

// interface ErrorProps {
//   title: string;
//   message: string;
//   statusCode?: number;
//   icon: string;
//   buttonText: string;
//   isRefreshBtn?: boolean;
// }

// export enum ErrorType {
//   UNAUTHORIZED = "UNAUTHORIZED",
//   TERMS_UPDATED = "TERMS_UPDATED",
//   TERMS_NAVIGATE = "TERMS_NAVIGATE",
//   NO_CONNECTION = "NO_CONNECTION",
//   RUNTIME = "RUNTIME",
//   GENERAL = "GENERAL",
// }

// export interface ErrorDetails {
//   type: ErrorType;
//   method: string | undefined;
//   props?: ErrorProps;
// }

export const getError = (error: any): Error => {
  const statusCode = error.response?.status;

  if (!error.response) return new NoConnectionError();

  switch (statusCode) {
    case 401:
      return new NotAuthonticatedError();
    case 403:
      return new UnauthorizedError();
    case 408:
    case 500:
    case 503:
    case 522:
    case 523:
    case 524:
    case 599:
      return new NoConnectionError();
  }

  if (axios.isAxiosError(error)) {
    const responseData: any = (error as AxiosError).response?.data;
    return new ServerError(responseData);
  }

  return new GeneralError(error.message, error.stack);
};
