import { AxiosInstance } from "axios";
import i18n from "i18next";
import StorageManager from "../../services/storage";

// type FailedRequest = {
//   resolve: (value?: any) => void;
//   reject: (value?: any) => void;
// };

// let isRefreshing = false;
// let failedQueue: FailedRequest[] = [];

// const processQueue = (error?: any) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve();
//     }
//   });

//   failedQueue = [];
// };

const UNPROTECTED_ROUTES: string[] = [
  "/participant/auth/onboard",
  "/participant/auth/login",
];

export const setupInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      const token = StorageManager.getTokenData()?.access_token;
      const pathname = config.url;

      if (token && pathname && !UNPROTECTED_ROUTES.includes(pathname)) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      // Add the current language to Accept-Language header
      const currentLanguage = i18n.language;
      config.headers["Accept-Language"] = currentLanguage.toLowerCase();

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      if (
        error.response?.data.err_code === 401 ||
        error.response?.data.err_code === 403
      ) {
        StorageManager.removeToken();

        error.response.status = error.response?.data.err_code;
      }

      return Promise.reject(error);
    }
  );

  // instance.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },

  //   async (error) => {
  //     const originalRequest = error.config;
  //     const refreshURL = refreshTokenService("").url;
  //     const userType = store.getState().auth.userType;

  //     const loginURL = userType === "carer" ? carerLoginService("").url : participantLoginService("").url;
  //     const isRefreshOrLogin =
  //       originalRequest.url.includes(refreshURL) ||
  //       originalRequest.url.includes(loginURL);

  //     if (
  //       error.response?.status === 401 &&
  //       !originalRequest._retry &&
  //       !isRefreshOrLogin
  //     ) {
  //       if (isRefreshing) {
  //         return new Promise((resolve, reject) => {
  //           failedQueue.push({ resolve, reject });
  //         })
  //           .then(() => {
  //             return instance(originalRequest);
  //           })
  //           .catch((err) => {
  //             return Promise.reject(err);
  //           });
  //       }

  //       originalRequest._retry = true;
  //       isRefreshing = true;

  //       const refreshToken = store.getState().auth.refreshToken;

  //       if (refreshToken === null) {
  //         processQueue(new Error("No refresh token found"));
  //         return Promise.reject(error);
  //       }

  //       try {
  //         const response = await instance(refreshTokenService(refreshToken));
  //         store.dispatch(
  //           setTokens({
  //             accessToken: response.data.access_token,
  //             refreshToken: response.data.refresh_token,
  //           })
  //         );

  //         processQueue();
  //         isRefreshing = false;
  //         return instance(originalRequest);
  //       } catch (err) {
  //         processQueue(err);
  //         isRefreshing = false;
  //         return Promise.reject(err);
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );
};
