import ErrorBox from "../components/Modal/ErrorBox";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { configService, SupportMethod } from "../services/configService";
import CardContainer from "./CardContainer";

const Support = () => {
  const { t } = useTranslation();
  const supportContact = configService.supportContact();
  const supportMethod = configService.config.supportMethod;

  function supportText(): string {
    const supportKey =
      supportMethod === SupportMethod.email
        ? "help_emailSupport"
        : supportMethod === SupportMethod.telephone
        ? "help_callSupport"
        : "";

    const supportText = t(supportKey, {
      client: configService.config.clientName,
      email: supportContact,
    });
    return supportText;
  }

  return (
    <CardContainer>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {t("tabBar_support")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <ErrorBox
          data-test-id="support-alert"
          text={t("helpAndSupport_emergencyWarningDescription")}
        />
        <Typography
          data-test-id="support-title"
          variant="h6"
          component="h2"
          my={2}
        >
          {t("helpAndSupport_howCanWeHelp")}
        </Typography>
        <Typography
          data-test-id="support-description"
          fontWeight="500"
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: supportText().replace(
              supportContact,
              `<a dir="ltr" href="tel:${supportContact}">${supportContact}</a>`
            ),
          }}
        ></Typography>
      </CardContainer.Content>
    </CardContainer>
  );
};
export default Support;
