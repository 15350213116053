import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux";
import { store, persistor, reset } from "./redux/store";
import { EvervaultProvider } from "@evervault/react";
import { PersistGate } from "redux-persist/integration/react";
// import * as Sentry from "@sentry/react";
import { CssBaseline } from "@mui/material";
import "./styles/global.scss";
import "./index.css";
import { TitleProvider } from "./common/TitleContext";
import StorageManager from "./services/storage";
import { v4 as uuidv4 } from "uuid";
import MUIWrapper from "./containers/MUIWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

if (!StorageManager.getDeviceId()) {
  StorageManager.setDeviceId(uuidv4());
}

// Sentry.init({
//   environment: import.meta.env.VITE_SENTRY_ENV,
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         // /^http:\/\/localhost:300/,
//         /^https:\/\/velocity\.nmible-staging\.com/,
//         /^https:\/\/velocity\.nmible\.net/,
//         /^https:\/\/trialkit\.nmible-staging\.com/,
//         /^https:\/\/trialkit\.nmible\.net/,
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <EvervaultProvider
          appId={import.meta.env.VITE_EVERVAULT_APP}
          teamId={import.meta.env.VITE_EVERVAULT_TEAM}
        >
          <MUIWrapper>
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <TitleProvider>
                <Suspense>
                  <App />
                </Suspense>
              </TitleProvider>
            </PersistGate>
          </MUIWrapper>
        </EvervaultProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
