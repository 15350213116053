import ErrorBase from "./ErrorBase";

export class NoConnectionError extends ErrorBase {
  constructor() {
    super({
      name: "NoConnectionError",
      title: "connectionLost_message",
      message: "connectionLost_message",
      icon: "/images/no_connection.svg",
    });
    Object.setPrototypeOf(this, NoConnectionError.prototype);
  }
}
