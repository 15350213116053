import { Modal as BaseModal } from "@mui/base/Modal";
import { Box, Button, Divider, Fade, Stack, Typography } from "@mui/material";
import { css, styled } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import CardContainer from "../CardContainer";

interface TaxPreviewProps {
  mode: DisplayMode;
  isOpen?: boolean;
  onClose?: () => void;
  onContinue: () => void;
}

export enum DisplayMode {
  fullpage,
  modal,
}

function TaxPreview({
  mode,
  isOpen = true,
  onClose,
  onContinue,
}: TaxPreviewProps) {
  const { t } = useTranslation();

  const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>(
    (props, ref) => {
      const { open, ...other } = props;
      return (
        <Fade in={open}>
          <div ref={ref} {...other} />
        </Fade>
      );
    }
  );

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
  };

  const ModalContent = styled("div")(
    ({ theme }) => css`
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === "dark"
          ? "rgb(0 0 0 / 0.5)"
          : "rgb(0 0 0 / 0.2)"};
      padding: 24px;
      color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `
  );

  function content() {
    return (
      <>
        <Typography data-test-id="tax-preview-p1" variant="body1">
          {t("tax_onboarding_intro_description_p1")}
        </Typography>
        <Typography data-test-id="tax-preview-p2" variant="body1">
          {t("tax_onboarding_intro_description_p2")}
        </Typography>
        <Typography data-test-id="tax-preview-p3" variant="body1">
          {t("tax_onboarding_intro_description_p3")}
        </Typography>
        <Typography data-test-id="tax-preview-p4" variant="body1">
          {t("tax_onboarding_intro_description_p4")}
        </Typography>
      </>
    );
  }

  function fullPageContent() {
    return (
      <CardContainer>
        <CardContainer.Content>
          <Box sx={{ margin: "auto" }}>
            <Typography data-test-id="tax-preview-p1" variant="h6" mb={2}>
              {t("tax_submit")}
            </Typography>
            {content()}
            <Button
              fullWidth
              data-test-id="tax-preview-primary-button"
              sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
              variant="contained"
              color="primary"
              onClick={onContinue}
            >
              {t("continue_text")}
            </Button>
          </Box>
        </CardContainer.Content>
      </CardContainer>
    );
  }

  if (mode === DisplayMode.fullpage) {
    return fullPageContent();
  }

  return (
    <Modal
      data-test-id="tax-preview-container"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => {
        onClose && onClose();
      }}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={isOpen}>
        <ModalContent sx={style}>
          <Typography variant="h5">{t("auth_welcomeToApp")}</Typography>
          <Divider></Divider>
          {content()}

          <Stack direction="row" justifyContent={"right"} spacing={2} mt={2}>
            <Button
              data-test-id="tax-preview-secondary-button"
              sx={{ textTransform: "initial" }}
              variant="outlined"
              color="secondary"
              onClick={onClose}
            >
              {t("skip")}
            </Button>
            <Button
              data-test-id="tax-preview-primary-button"
              sx={{ textTransform: "initial", fontWeight: "bold" }}
              variant="contained"
              color="primary"
              onClick={onContinue}
            >
              {t("continue_text")}
            </Button>
          </Stack>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

export default TaxPreview;
