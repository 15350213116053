import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar, Badge, Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getNameInitials } from "../common/helpers";
import { RootState } from "../redux/store";
import StorageManager from "../services/storage";
import { toTitleCase } from "./Stipend/StipendCommon";
import { useEffect, useState } from "react";

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    my: 5,
  },
  avatar: {
    bgcolor: "light",
    width: "60px",
    height: "60px",
  },
  yourChannel: {
    mt: 1,
  },
};

interface IAppMenuProps {
  collapsed: boolean;
  onSidebarCollapse: () => void;
}

const AppMenu: React.FC<IAppMenuProps> = ({ collapsed, onSidebarCollapse }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useSelector((state: RootState) => state.auth.profile);

  const path = useLocation().pathname;
  // const isCarer = StorageManager.getLoginInformation()?.userType === "carer";

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const [isSidebarToggled, setIsSidebarToggled] = useState<boolean>(collapsed);
  const [isSidebarBroken, setIsSidebarBroken] = useState<boolean>(false);

  useEffect(() => {
    setIsSidebarToggled(collapsed);
  }, [collapsed]);

  return (
    <Sidebar
      rtl={theme.direction === "rtl"}
      style={{ height: "100%", top: "auto" }}
      breakPoint="md"
      backgroundColor={theme.palette.background.default}
      collapsed={isSidebarBroken ? false : collapsed}
      toggled={isSidebarToggled}
      onBackdropClick={() => setIsSidebarToggled(false)}
      onBreakPoint={setIsSidebarBroken}
    >
      <Box sx={styles.avatarContainer}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar sx={styles.avatar} alt="">
            {getNameInitials(profile?.name ?? "")}
          </Avatar>
        </StyledBadge>

        {!collapsed && profile?.name && (
          <Typography variant="body2" sx={styles.yourChannel}>
            {toTitleCase(profile.name)}
          </Typography>
        )}

        {!collapsed && (
          <Typography variant="overline" fontWeight="bold">
            {profile?.participant_number}
          </Typography>
        )}
      </Box>

      <Menu
        menuItemStyles={{
          button: ({ level, active }) => {
            return {
              backgroundColor: active ? theme.palette.divider : undefined,
            };
          },
        }}
        onClick={() => {
          if (isSidebarBroken) {
            setIsSidebarToggled(false);
            onSidebarCollapse();
          }
        }}
      >
        <MenuItem
          key="payments"
          active={path === "/payments"}
          component={<Link to="/" />}
          icon={<HomeOutlinedIcon />}
        >
          <Typography variant="body2">{t("tabBar_home")}</Typography>{" "}
        </MenuItem>

        {/* {!isCarer && (
          <MenuItem
            key="carers"
            active={path === "/carers"}
            component={<Link to="/carers" />}
            icon={<PeopleAltIcon />}
          >
            <Typography variant="body2">{t("settings_carers")}</Typography>
          </MenuItem>
        )} */}

        <MenuItem
          key="visits"
          active={path === "/visits"}
          component={<Link to="/visits" />}
          icon={<CalendarTodayOutlinedIcon />}
        >
          <Typography variant="body2">{t("visits_title")}</Typography>
        </MenuItem>

        <MenuItem
          key="trial"
          active={path === "/trial"}
          component={<Link to="/trial" />}
          icon={<LocationCityIcon />}
        >
          <Typography variant="body2">{t("visits_trialTitle")}</Typography>
        </MenuItem>

        <MenuItem
          key="support"
          active={path === "/support"}
          component={<Link to="/support" />}
          icon={<HelpOutlineOutlinedIcon />}
        >
          <Typography variant="body2">{t("tabBar_support")}</Typography>
        </MenuItem>

        <MenuItem
          key="tax"
          active={path === "/tax"}
          component={<Link to="/tax" />}
          icon={<AccountBalanceIcon />}
        >
          <Typography variant="body2">{t("Tax")}</Typography>
        </MenuItem>

        <MenuItem
          key="settings"
          active={path === "/settings"}
          component={<Link to="/settings" />}
          icon={<SettingsIcon />}
        >
          <Typography variant="body2">{t("settings")}</Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default AppMenu;
