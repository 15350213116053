import { CircularProgress, Skeleton } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import paymentService from "../api/paymentService";
import { getClaimById } from "../api/stipendsServices";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { resetCarerClaims, updateClaims } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { Claim } from "../types/paymentTypes";
import getPaymentDetails from "./PaymentDetailsProvider";
import SummaryPageUI from "./SummaryPageUI";
import { useReadExpenseById } from "../api/client/expenses/expenses";
import SummaryPageSkeleton from "../components/SummaryPageSkeleton";

function PaymentDetailsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleServerError } = useErrorHandler();
  const expenseTypes = useSelector(
    (state: RootState) => state.auth.expenseTypes
  );
  const { visits } = useVisits();
  const { id } = useParams<{ id: string }>();

  usePageTitle(t("payment_title"));

  const { data, isLoading } = useReadExpenseById(id!, {
    include:
      "expenseItems,currency,receipt,participantVisit.visit,expenseItems.expenseType",
  });

  if (!data?.data && !isLoading) return <Navigate to="/payments" />;

  const [isApproving, setApproving] = useState(false);
  const [isRejecting, setRejecting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: "",
    message: "",
    confirmButton: "",
    onConfirm: () => {},
  });

  const handleApprove = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_approveClaim"),
      message: t("claimDetail_approveClaimConfirmationMessage"),
      confirmButton: t("claimDetail_approve"),
      onConfirm: () => handleConfirmApprove(claim.id),
    });

    setDialogOpen(true);
  };

  const handleReject = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_rejectClaim"),
      message: t("claimDetail_rejectClaimConfirmationMessage"),
      confirmButton: t("claimDetail_reject"),
      onConfirm: () => handleConfirmReject(claim.id),
    });

    setDialogOpen(true);
  };

  const handleConfirmApprove = async (claimId: number) => {
    // setDialogOpen(false);
    // setApproving(true);
    // try {
    //   await api(paymentService.approveClaim(claimId));
    //   dispatch(showToast(t("claim_toastConfirmationMessage")));
    //   dispatch(resetCarerClaims());
    // } catch (error) {
    //   handleServerError(error as AxiosError);
    // } finally {
    //   navigate("/payments");
    //   setApproving(false);
    // }
  };

  const handleConfirmReject = async (claimId: number) => {
    // setDialogOpen(false);
    // setRejecting(true);
    // try {
    //   await api(paymentService.rejectClaim(claimId));
    //   dispatch(showToast(t("claimDetail_claimRejected")));
    //   dispatch(resetCarerClaims());
    // } catch (error) {
    //   handleServerError(error as AxiosError);
    // } finally {
    //   navigate("/payments");
    //   setRejecting(false);
    // }
  };

  if (isLoading || !data) {
    return (
      <SummaryPageSkeleton
        showChangePaymentMethodButton={false}
        detailSectionsCount={1}
        actionsCount={0}
      />
    );
  }
  return (
    <>
      <SummaryPageUI
        {...getPaymentDetails(
          data,
          expenseTypes,
          handleApprove,
          handleReject,
          isApproving,
          isRejecting
        )}
      />

      <ConfirmationDialog
        open={isDialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        confirmButtonText={dialogConfig.confirmButton}
        cancelButtonText={t("cancel")}
        onConfirm={dialogConfig.onConfirm}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
}

export default PaymentDetailsPage;
