import React, { useState } from "react";
import { Box } from "@mui/material";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { heicTo, isHeic } from "heic-to";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface ImageUploaderProps {
  files: File[];
  onFileChange: (files: File[]) => void;
  maxFiles?: number;
  maxSize?: number;
}

export default function ImageUploader({
  files,
  onFileChange,
  maxFiles = 1,
  maxSize = 7340032, // 7MB default
}: ImageUploaderProps) {
  const { t } = useTranslation();
  const [isConverting, setIsConverting] = useState(false);

  const convertToJpeg = async (file: File): Promise<File> => {
    if (await isHeic(file)) {
      const heifImageBlob = await heicTo({
        blob: file,
        type: "image/jpeg",
        quality: 0.8,
      });
      return new File([heifImageBlob], file.name.replace(/\.heic$/i, ".jpg"), {
        type: "image/jpeg",
        lastModified: Date.now(),
      });
    }
    return file;
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    setIsConverting(true);
    try {
      const processedFiles = await Promise.all(
        acceptedFiles.map((file) => convertToJpeg(file))
      );

      onFileChange([...files, ...processedFiles]);
    } catch (error) {
      console.error("File processing error:", error);
    } finally {
      setIsConverting(false);
    }
  };

  return (
    <Box
      sx={{
        border: "dashed",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderRadius: "12px",
        height: 250,
        textAlign: "center",
        cursor: "pointer",
        position: "relative",
        opacity: isConverting ? 0.5 : 1,
      }}
    >
      <Dropzone
        onDrop={handleDrop}
        accept={{
          "image/*": [".jpg", ".jpeg", ".png", ".gif", ".heic"],
        }}
        maxFiles={maxFiles}
        maxSize={maxSize}
        multiple={false}
        disabled={isConverting}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <Box
            {...getRootProps()}
            data-test-id="uploader"
            sx={{
              height: "100%",
              outline: "none",
              color: isDragReject
                ? "#d32f2f" // Error color
                : isDragActive
                ? "#1976d2" // Highlighted color
                : "#000",
            }}
          >
            <input {...getInputProps()} />
            <p
              style={{
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: 400,
                fontSize: "1.5rem",
                lineHeight: 1.334,
              }}
            >
              {isConverting
                ? t("loading_text")
                : isDragReject
                ? t("unsupported_file_type")
                : isDragActive
                ? t("drop_files_here")
                : t("drag_and_drop")}
            </p>
            <CloudUploadIcon sx={{ fontSize: 48 }} />
          </Box>
        )}
      </Dropzone>
    </Box>
  );
}
