/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ExpenseTypeCreateRequestBody,
  ExpenseTypeCreateResponseResponse,
  ExpenseTypeDeleteResponseResponse,
  ExpenseTypeIndexResponseResponse,
  ExpenseTypeReadResponseResponse,
  ExpenseTypeRestoreResponseResponse,
  ExpenseTypeUpdateRequestBody,
  ExpenseTypeUpdateResponseResponse,
} from "../../model/resources-expense-types.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewExpenseTypeParams,
  ListAllExpenseTypesParams,
  NotFoundErrorResponseResponse,
  ReadExpenseTypeByIdParams,
  RestoreExpenseTypeByIdParams,
  ServerErrorResponseResponse,
  UpdateExpenseTypeByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.index` permission.

# Extra information
- This endpoint will return a paginated list of expense-types.

 * @summary List all expense-types.
 */
export const listAllExpenseTypes = (
  params?: ListAllExpenseTypesParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseTypeIndexResponseResponse>(
    { url: `/expense-types`, method: "GET", params, signal },
    options
  );
};

export const getListAllExpenseTypesQueryKey = (
  params?: ListAllExpenseTypesParams
) => {
  return [`/expense-types`, ...(params ? [params] : [])] as const;
};

export const getListAllExpenseTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllExpenseTypes>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseTypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllExpenseTypesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAllExpenseTypes>>
  > = ({ signal }) => listAllExpenseTypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllExpenseTypes>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllExpenseTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllExpenseTypes>>
>;
export type ListAllExpenseTypesQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllExpenseTypes<
  TData = Awaited<ReturnType<typeof listAllExpenseTypes>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllExpenseTypesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseTypes>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenseTypes>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllExpenseTypes<
  TData = Awaited<ReturnType<typeof listAllExpenseTypes>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseTypes>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllExpenseTypes>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllExpenseTypes<
  TData = Awaited<ReturnType<typeof listAllExpenseTypes>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseTypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all expense-types.
 */

export function useListAllExpenseTypes<
  TData = Awaited<ReturnType<typeof listAllExpenseTypes>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllExpenseTypesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllExpenseTypes>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllExpenseTypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.create` permission.

# Extra information
- This endpoint will return the newly created expense type.

 * @summary Create a new expense type.
 */
export const createNewExpenseType = (
  expenseTypeCreateRequestBody: ExpenseTypeCreateRequestBody,
  params?: CreateNewExpenseTypeParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseTypeCreateResponseResponse>(
    {
      url: `/expense-types`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseTypeCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewExpenseTypeMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpenseType>>,
    TError,
    { data: ExpenseTypeCreateRequestBody; params?: CreateNewExpenseTypeParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewExpenseType>>,
  TError,
  { data: ExpenseTypeCreateRequestBody; params?: CreateNewExpenseTypeParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewExpenseType>>,
    { data: ExpenseTypeCreateRequestBody; params?: CreateNewExpenseTypeParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewExpenseType(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewExpenseTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewExpenseType>>
>;
export type CreateNewExpenseTypeMutationBody = ExpenseTypeCreateRequestBody;
export type CreateNewExpenseTypeMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new expense type.
 */
export const useCreateNewExpenseType = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewExpenseType>>,
    TError,
    { data: ExpenseTypeCreateRequestBody; params?: CreateNewExpenseTypeParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewExpenseType>>,
  TError,
  { data: ExpenseTypeCreateRequestBody; params?: CreateNewExpenseTypeParams },
  TContext
> => {
  const mutationOptions = getCreateNewExpenseTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.read` permission.

# Extra information
- This endpoint will the requested expense type.

 * @summary Read expense-type by ID.
 */
export const readExpenseTypeById = (
  id: string,
  params?: ReadExpenseTypeByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<ExpenseTypeReadResponseResponse>(
    { url: `/expense-types/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadExpenseTypeByIdQueryKey = (
  id: string,
  params?: ReadExpenseTypeByIdParams
) => {
  return [`/expense-types/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadExpenseTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readExpenseTypeById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseTypeByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadExpenseTypeByIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof readExpenseTypeById>>
  > = ({ signal }) => readExpenseTypeById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readExpenseTypeById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadExpenseTypeByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readExpenseTypeById>>
>;
export type ReadExpenseTypeByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadExpenseTypeById<
  TData = Awaited<ReturnType<typeof readExpenseTypeById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadExpenseTypeByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseTypeById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseTypeById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadExpenseTypeById<
  TData = Awaited<ReturnType<typeof readExpenseTypeById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseTypeByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseTypeById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readExpenseTypeById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadExpenseTypeById<
  TData = Awaited<ReturnType<typeof readExpenseTypeById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseTypeByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read expense-type by ID.
 */

export function useReadExpenseTypeById<
  TData = Awaited<ReturnType<typeof readExpenseTypeById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadExpenseTypeByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readExpenseTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadExpenseTypeByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated expense type.

 * @summary Update expense-type by ID.
 */
export const updateExpenseTypeById = (
  id: string,
  expenseTypeUpdateRequestBody: ExpenseTypeUpdateRequestBody,
  params?: UpdateExpenseTypeByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseTypeUpdateResponseResponse>(
    {
      url: `/expense-types/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: expenseTypeUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateExpenseTypeByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseTypeById>>,
    TError,
    {
      id: string;
      data: ExpenseTypeUpdateRequestBody;
      params?: UpdateExpenseTypeByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateExpenseTypeById>>,
  TError,
  {
    id: string;
    data: ExpenseTypeUpdateRequestBody;
    params?: UpdateExpenseTypeByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateExpenseTypeById>>,
    {
      id: string;
      data: ExpenseTypeUpdateRequestBody;
      params?: UpdateExpenseTypeByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateExpenseTypeById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateExpenseTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateExpenseTypeById>>
>;
export type UpdateExpenseTypeByIdMutationBody = ExpenseTypeUpdateRequestBody;
export type UpdateExpenseTypeByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update expense-type by ID.
 */
export const useUpdateExpenseTypeById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExpenseTypeById>>,
    TError,
    {
      id: string;
      data: ExpenseTypeUpdateRequestBody;
      params?: UpdateExpenseTypeByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateExpenseTypeById>>,
  TError,
  {
    id: string;
    data: ExpenseTypeUpdateRequestBody;
    params?: UpdateExpenseTypeByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateExpenseTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a expense-type by ID.
 */
export const deleteExpenseTypeById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseTypeDeleteResponseResponse>(
    { url: `/expense-types/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteExpenseTypeByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseTypeById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteExpenseTypeById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteExpenseTypeById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteExpenseTypeById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteExpenseTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteExpenseTypeById>>
>;

export type DeleteExpenseTypeByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a expense-type by ID.
 */
export const useDeleteExpenseTypeById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExpenseTypeById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteExpenseTypeById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteExpenseTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `expense-types.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored expense type.

 * @summary Restore a expense-type by ID.
 */
export const restoreExpenseTypeById = (
  id: string,
  params?: RestoreExpenseTypeByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<ExpenseTypeRestoreResponseResponse>(
    { url: `/expense-types/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreExpenseTypeByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseTypeById>>,
    TError,
    { id: string; params?: RestoreExpenseTypeByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreExpenseTypeById>>,
  TError,
  { id: string; params?: RestoreExpenseTypeByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreExpenseTypeById>>,
    { id: string; params?: RestoreExpenseTypeByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreExpenseTypeById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreExpenseTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreExpenseTypeById>>
>;

export type RestoreExpenseTypeByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a expense-type by ID.
 */
export const useRestoreExpenseTypeById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreExpenseTypeById>>,
    TError,
    { id: string; params?: RestoreExpenseTypeByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreExpenseTypeById>>,
  TError,
  { id: string; params?: RestoreExpenseTypeByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreExpenseTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
