// Token Slice in Redux
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Profile } from "../../types/common";
import { INotificatonPreferences } from "../../api/notificationService";
import { VehicleQuestion } from "../../types/paymentTypes";
import { RootState } from "../store";
import StorageManager from "../../services/storage";
import { formatLanguageCode } from "../../common/helpers";
import { StudyJsonApiBlockWithId } from "../../api/model/resources-studies.yml";
import { SiteJsonApiBlockWithId } from "../../api/model/resources-sites.yml";
import { ArmJsonApiBlockWithId } from "../../api/model/resources-arms.yml";
import { LanguageJsonApiBlockWithId } from "../../api/model/resources-languages.yml";
import { ExpenseTypeJsonApiBlockWithId } from "../../api/model/resources-expense-types.yml";
import { ParticipantAccountDetailsResponseResponse } from "../../api/model/resources-participant.yml";

interface AuthState {
  profile?: Profile;
  notificationPreferences?: INotificatonPreferences;
  arm?: ArmJsonApiBlockWithId;
  site?: SiteJsonApiBlockWithId;
  study?: StudyJsonApiBlockWithId;
  languages: LanguageJsonApiBlockWithId[];
  expenseTypes: ExpenseTypeJsonApiBlockWithId[];
}

const initialState: AuthState = {
  profile: undefined,
  notificationPreferences: undefined,
  arm: undefined,
  site: undefined,
  study: undefined,
  languages: [],
  expenseTypes: [],
};
export const RESET_STATE = "RESET_STATE";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updatePreferredLanguage: (state, action) => {
      if (!state.profile) return;
      const lang: LanguageJsonApiBlockWithId = action.payload;
      state.profile = {
        ...state.profile,
        preferred_language: lang,
      };
    },
    setProfile: (state, action) => {
      const participantResponse: ParticipantAccountDetailsResponseResponse =
        action.payload;
      const included = participantResponse.included;

      state.arm = included?.filter((item: any) => item.type === "arms")?.[0];
      state.study = included?.filter(
        (item: any) => item.type === "studies"
      )?.[0];
      state.site = included?.filter((item: any) => item.type === "sites")?.[0];
      const account = participantResponse.data!.attributes;
      let accountLanguage: LanguageJsonApiBlockWithId =
        participantResponse.included?.filter(
          (item: any) => item.type === "languages"
        )?.[0];
      if (accountLanguage)
        accountLanguage.attributes.code = formatLanguageCode(
          accountLanguage.attributes.code
        );

      state.profile = {
        claimant_id: parseInt(participantResponse.data!.id!, 10),
        participant_number: account.clientId,
        country: "US",
        currency: "USD",
        is_locked: false,
        is_displaced: false,
        preferred_language: accountLanguage,
        distance_unit:
          state.site?.attributes?.travelRateUnitOfMeasurement ?? "kilometers",
        are_tc_rejected: true,
        hide_visits_meta_info: false,
        distance_rule_screens: [],
        available_claim_types: ["Receipt", "Mileage"],
        latest_accepted_tc: undefined,
        original_country: null,
        name: null,
        email: null,
        is_email_verified: null,
      };

      participantResponse.data;
    },
    setExpenseTypes: (state, action) => {
      state.expenseTypes = action.payload;
    },
    setNotificationPreferences: (state, action) => {
      state.notificationPreferences = action.payload;
    },
    setLanguages: (state, action) => {
      const langs: LanguageJsonApiBlockWithId[] = action.payload;
      const formattedLangs = langs.map((lang) => ({
        ...lang,
        attributes: {
          ...lang.attributes,
          code: formatLanguageCode(lang.attributes.code),
        },
      }));
      state.languages = formattedLangs;
    },
    updateVehicleDetails: (state, action: PayloadAction<VehicleQuestion[]>) => {
      if (state.profile) {
        const updatedQuestions = action.payload.map((question) => ({
          ...question,
          selected_option: question.selected_option
            ? { ...question.selected_option }
            : undefined,
        }));
        state.profile.distance_rule_screens = updatedQuestions;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

const selectAuthState = (state: RootState) => state.auth;

export const selectIsAuthenticated = createSelector(
  [selectAuthState],
  (auth) => {
    const token = StorageManager.getTokenData();

    if (!token?.access_token || token.requiresPinChange) {
      return false;
    } else {
      return true;
    }

    // TODO: Fix problem with token expiration - we are not recieveing that on login.
    // Currently commented out to access the app.
    // const currentTime = Date.now() / 1000;

    // return token.access_token && token.expires && token.expires > currentTime;
  }
);

export const selectCurrency = createSelector([selectAuthState], (auth) =>
  auth.profile ? auth.profile.currency : "USD"
);

export const {
  updateVehicleDetails,
  setExpenseTypes,
  setProfile,
  setLanguages,
  setNotificationPreferences,
  updatePreferredLanguage,
} = authSlice.actions;
export default authSlice.reducer;
